import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  therapists: [],
  location: '',
  lat: '',
  lng: '',
  name: '',
  category: {},
  subCategory: {}
};
const searchedTherapistsSlice = createSlice({
  name: 'searchedTherapists',
  initialState: initialState,
  reducers: {
    setSearchedTherapists: (state, action) => {
      state.therapists = action.payload;
    },
    setFilterValues: (state, action) => {
      const { category, location, lat, lng, name, subCategory } =
        action.payload;
      state.location = location;
      state.lat = lat;
      state.lng = lng;
      state.name = name;
      state.category = category;
      state.subCategory = subCategory;
    },
    resetFilterValues: state => {
      Object.assign(state, initialState);
    }
  }
});

export const { setSearchedTherapists, setFilterValues, resetFilterValues } =
  searchedTherapistsSlice.actions;
export const searchedTherapistsReducer = searchedTherapistsSlice.reducer;
