import React from "react";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import "./termsConditions.scss";
import { newTermsConditionsArray, termsConditionsArray } from "./constant";

const TermsConditions = () => {
  return (
    <>
      <Header />
      <div className="mainPolicyWrap">
        <div className="policyHead">
          {/* <h2>Legal</h2>
          <h5>Documentation for how we manage data</h5> */}
          <h2 style={{ textAlign: "center" }}>Terms and Conditions of Use </h2>
        </div>
        <div className="policylists">
          <ol type="1">
            {newTermsConditionsArray?.map((parentData) => {
              return (
                <li className="parentList">
                  <h5>{parentData?.heading}</h5>
                  <ol type="a">
                    {parentData?.content?.map((childData) => {
                      return (
                        <li>
                          {childData?.text}
                          <ol type="i">
                            {childData?.innerContent?.map((subCont) => {
                              return (
                                <li>
                                  {subCont?.text}
                                  <ol type="1">
                                    {subCont?.subInnerContent?.map(
                                      (subSubCont) => {
                                        return <li>{subSubCont?.text}</li>;
                                      }
                                    )}
                                  </ol>
                                </li>
                              );
                            })}
                          </ol>
                        </li>
                      );
                    })}
                  </ol>
                </li>
              );
            })}
          </ol>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default TermsConditions;
