import Button from '../../components/UI/Button/Button';
import AntdInput from '../../components/UI/Input';
import { useNavigate } from 'react-router-dom';
import './OtpVerification.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import {
  setEmail,
  setToken,
  setUser,
  updateAuthField
} from '../../Redux/Reducers/authSlice';
import { postRequest, putRequest } from '../../api/apiCalls';
import { AuthLayout } from '../../components/layout/AuthLayout';
import { message } from 'antd';
import ButtonGreen from '../../components/UI/Button/ButtonGreen';

const OtpOverification = () => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [otpVal, setOtpVal] = useState('');
  const email = useSelector(state => state.authReducer.email);
  const [isResendDisabled, setisResendDisabled] = useState(false);

  const submitHandler = async () => {
    setIsLoading(true);

    const onSuccess = res => {
      dispatch(setEmail(''));
      setOtpVal('');
      setIsLoading(false);
      const userLoginData = {
        email: res?.data.email,
        role: res?.data.role,
        userId: res?.data.userId
      };
      dispatch(setUser(userLoginData));
      dispatch(setToken(res?.data.token));
      dispatch(
        updateAuthField({
          field: 'isProfileFilled',
          value: res?.data?.isProfileFilled
        })
      );
      localStorage.setItem('token', res?.data?.token);
      setIsLoading(false);
      navigate('/');
    };
    const onError = res => {
      setIsLoading(false);

      console.log(res, 'erorr res');
    };
    await postRequest(
      {
        email,
        otp: otpVal
      },
      'customer/verify-otp-customer',
      true,
      false,
      onSuccess,
      onError
    );
  };
  const resendOTP = async () => {
    if (isResendDisabled) {
      message.info('Please wait for 30 seconds');
      return;
    }
    const onSuccess = res => {
      setIsLoading(false);
      setisResendDisabled(true);

      setTimeout(() => {
        setisResendDisabled(false);
      }, 30000);
    };
    const onError = () => {
      setIsLoading(false);
    };
    await putRequest(
      {
        email,
        role: 'customer'
      },
      'auth/resend-OTP',
      true,
      false,
      onSuccess,
      onError
    );
  };
  return (
    <AuthLayout>
      {/* <ResponsiveAuthHeader /> */}
      <div className='otp-screen-main'>
        <div className='otp-main-content'>
          <div className='otp-header'>
            <h4>OTP verification</h4>
          </div>
          <p style={{ textAlign: 'center' }}>
            Verify the OTP code sent to your email address. Please check your
            junk or spam folder if your code doesn’t come through within a few
            minutes!
          </p>
          <div className='Otp-input-container'>
            <AntdInput
              type='number'
              placeholder='OTP code'
              onChange={e => {
                setOtpVal(e.target.value);
              }}
            />{' '}
            <div className='parentResentOTPButton'>
              <p
                className='resentOTPButton'
                style={{
                  opacity: isResendDisabled ? 0.6 : 1,
                  marginTop: '5px'
                }}
                onClick={() => resendOTP()}
              >
                Resend OTP
              </p>
            </div>
          </div>
          <div className='btn-center'>
            <ButtonGreen
              text={isLoading ? 'Verifying..' : 'Verify'}
              onClick={submitHandler}
              disabled={otpVal.length < 4}
            />
          </div>
        </div>
      </div>
    </AuthLayout>
  );
};

export default OtpOverification;
