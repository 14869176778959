import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';

const useAuth = () => {
  const auth = useSelector(state => state.authReducer.token);
  return auth;
};

const PrivateRoute = () => {
  return useAuth() ? <Outlet /> : <Navigate to='/login' />;
};
export default PrivateRoute;
