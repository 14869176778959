import { Col, Row } from 'antd';
import React from 'react';
import { ArrowVector, PackagesCover } from '../../assets/images';
import './PsychFindProvider.scss';
import ButtonPurple from '../UI/Button/ButtonPurple';
import { useNavigate } from 'react-router-dom';
import {
  FindProviderDotted,
  FindproviderArt,
  FindproviderArt2,
  FindproviderSearch
} from '../../assets/vectors';
export const PsychFindProvider = () => {
  const navigate = useNavigate();
  return (
    <div className={'psychFindProviderWrapper'}>
      <div className={'psychContainer'}>
        <Row>
          <Col
            lg={10}
            md={10}
            sm={10}
            xs={24}
            className='findProviderCard leftCard'
          >
            <div className='topBar'>
              <img src={FindproviderArt} alt='$$$' />
              <img src={FindproviderArt2} alt='$$$' />
            </div>
            <div className='content'>
              <p>
                Need urgent assistance? We're here to help. Get immediate
                support.
              </p>
            </div>
            <div className='bottomBar'>
              <button>Urgent help</button>
            </div>
          </Col>
          <Col l lg={4} md={4} sm={4} xs={24}>
            <div className='findProviderCenterBG'>
              <img src={FindProviderDotted} alt='$$$' />
            </div>
          </Col>
          <Col
            lg={10}
            md={10}
            sm={10}
            xs={24}
            className='findProviderCard rightCard'
          >
            <div className='topBar'>
              <img src={FindproviderArt} alt='$$$' />
              <img src={FindproviderSearch} alt='$$$' />
            </div>
            <div className='content'>
              <p>
                Reclaim your inner peace and thrive in a life of emotional
                well-being.
              </p>
            </div>
            <div className='bottomBar'>
              {/* <button>Urgent help</button> */}
            </div>
          </Col>
        </Row>

        <Row gutter={[10, 10]} style={{ marginTop: '80px' }}>
          <Col lg={10}>
            <h1>Find a provider for...</h1>
            <div className={'packages-main-contaainer'}>
              <div className={'flexWrapper'}>
                <h2>Medication Management</h2>
                <ArrowVector />
              </div>
              {/* <p>
                Individual therapy, a one-on-one therapy or counseling, involves
                a therapeutic relationship between a client and a trained mental
                health professional.
              </p> */}
              <div className={'flexWrapper'}>
                <h2>Therapy/Counseling</h2>

                <ArrowVector />
              </div>
              {/* <p>
                Group therapy involves a small gathering of individuals,
                typically led by a provider, to address shared concerns and
                provide support. Participants in group therapy interact with one
                another, sharing experiences and insights in a confidential and
                supportive environment.
              </p> */}
            </div>
          </Col>
          <Col lg={14}>
            <div className={'imgWrapper'}>
              <PackagesCover />
            </div>
          </Col>

          <Col lg={24}>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <ButtonPurple
                text='Request An Appointment'
                className='largePurpleButton'
                onClick={() => navigate('/all-providers')}
              />
            </div>
            <p className='customEmergencyText'>
              If you are feeling suicidal or having emergency, call
              <span> 911 / 988</span>
            </p>
          </Col>
        </Row>
      </div>
    </div>
  );
};
