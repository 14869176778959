import { Input } from 'antd';
import './Password.scss';
const AntdPassword = ({ ...props }) => {
  return (
    <div className='antd-password-custom'>
      <Input.Password {...props} />
    </div>
  );
};

export default AntdPassword;
