import React, { useEffect, useState } from 'react';
import './notification.scss';
import { ChevronNoti, Chevron_Down_Orange } from '../../assets/vectors';
import Footer from '../footer/Footer';
import Header from '../header/Header';
import { getRequest } from '../../api/apiCalls';
import SpinLoader from '../UI/Loader';
import { useNavigate } from 'react-router-dom';

const Notification = ({ color }) => {
  const showFooter = true;
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [notificationsToShow, setNotificationsToShow] = useState(3);

  function categorizeNotifications(notifications) {
    const categorized = [];

    notifications.forEach(notification => {
      const createdAt = new Date(notification.createdAt);

      const dateKey = createdAt.toLocaleDateString('en-US', {
        day: 'numeric',
        month: 'short',
        year: 'numeric'
      });

      const existingCategoryIndex = categorized.findIndex(
        item => item.date === dateKey
      );

      if (existingCategoryIndex !== -1) {
        categorized[existingCategoryIndex].notifications.push(notification);
      } else {
        categorized.push({
          date: dateKey,
          notifications: [notification]
        });
      }
    });
    categorized.sort((a, b) => new Date(b.date) - new Date(a.date));
    return categorized;
  }
  const getNotifications = async () => {
    setIsLoading(true);
    const onSuccess = res => {
      const updatedNotificatins = categorizeNotifications(res.data);
      setNotifications(updatedNotificatins);
      setIsLoading(false);
    };
    const onError = () => {
      setIsLoading(false);
    };
    await getRequest(
      null,
      `customer/get-customer-notifications`,
      true,
      true,
      onSuccess,
      onError
    );
  };
  useEffect(() => {
    getNotifications();
  }, []);
  const handleShowMore = () => {
    // Increase the number of notifications to display
    setNotificationsToShow(notificationsToShow + 1);
  };
  return (
    <>
      <Header />
      {isLoading ? (
        <SpinLoader />
      ) : (
        <div className='notification'>
          <div className='head_notification'>
            <h2> Notifications </h2>
          </div>
          {notifications &&
            notifications
              .slice(0, notificationsToShow)
              ?.map((notification, ind) => {
                return (
                  <>
                    <h4> {notification?.date} </h4>{' '}
                    <div
                      className='noti_content'
                      style={{ marginBottom: '3rem' }}
                    >
                      {notification?.notifications.map(el => (
                        <div className='day_notification'>
                          <p> {el.notification} </p>
                          {el?.bookingId ? (
                            <button
                              onClick={() =>
                                navigate(`/appointment-detail/${el?.bookingId}`)
                              }
                            >
                              view
                              <img src={ChevronNoti} alt='' />
                            </button>
                          ) : null}
                        </div>
                      ))}
                    </div>
                  </>
                );
              })}
          {notificationsToShow < notifications.length && (
            <button className='notification_see_more' onClick={handleShowMore}>
              See more <img src={Chevron_Down_Orange} alt='' />
            </button>
          )}
        </div>
      )}

      {showFooter && <Footer color={false} />}
    </>
  );
};

export default Notification;
