import React from 'react';
import { DP, VerifiedVector } from '../../../assets/images';
import { Col, Rate, Row } from 'antd';
import './PsychTopProviderCards.scss';
import { BlueLineArrow } from '../../../assets/vectors';
import { useNavigate } from 'react-router-dom';
export const PsychTopProviderCards = ({ reviews, isAllProviders = false }) => {
  const navigate = useNavigate();
  const cardData = [
    {
      title: 'Dr. Sarah Green',
      role: 'Optometrist',
      experience: '12 years experience'
    },
    {
      title: 'Dr. Sarah Green',
      role: 'Optometrist',
      experience: '12 years experience'
    },
    {
      title: 'Dr. Sarah Green',
      role: 'Optometrist',
      experience: '12 years experience'
    },
    {
      title: 'Dr. Sarah Green',
      role: 'Optometrist',
      experience: '12 years experience'
    }
  ];
  return (
    <div className={'psychTopProviderCardsWrapper'}>
      <Row gutter={[20, 20]}>
        {reviews?.map((item, index) => {
          return (
            <Col
              lg={isAllProviders ? 8 : 12}
              md={isAllProviders ? 8 : 12}
              sm={12}
              xs={24}
            >
              <div className={'slider-card-sm'} key={index}>
                <div className={'card-main-coontainer'}>
                  {item?.personalInfo?.profileImg ? (
                    <img src={item?.personalInfo?.profileImg} alt='$$$' />
                  ) : (
                    <DP />
                  )}

                  <div className={'slide-content'}>
                    <p className={'slide-title-text'}>
                      {item?.personalInfo?.firstName +
                        ' ' +
                        item?.personalInfo?.lastName}
                    </p>
                    <p className={'slide-text-heading'}>
                      {item?.providerProfile?.practice[0]?.name}
                    </p>
                    <div className={'icon-experience'}>
                      <Rate value={item?.rating} disabled />
                      {/* <VerifiedVector />
                      <p className={'experience-title'}>{item.experience}</p> */}
                    </div>
                  </div>
                </div>
                <div className={'card-btn-section'}>
                  <div></div>
                  {/* <button className={'card-detail-btn'}>Details</button> */}
                  <button
                    className={'card-show-button'}
                    onClick={() => navigate(`/doctor-information/${item?._id}`)}
                  >
                    Schedule appointment{' '}
                    <img src={BlueLineArrow} alt='' className={'l-svg'} />{' '}
                  </button>
                </div>
              </div>
            </Col>
          );
        })}
      </Row>
    </div>
  );
};
