import { Table } from 'antd';
import moment from 'moment';
import React from 'react';
import { ArrowLeftTable } from '../../assets/vectors';

import { useNavigate } from 'react-router-dom';

export const ConsultationsTable = ({ data }) => {
  const navigate = useNavigate();
  const frequencyArray = {
    ONE_OFF: 'One-off',
    WEEKLY: 'Weekly',
    FORTNIGHTLY: 'Fortnightly',
    MONTHLY: 'Monthly'
  };
  const columns = [
    {
      title: 'Date & Time',
      dataIndex: 'scheduleInfo',

      render: val => {
        return moment(val?.start).format('YYYY/MM/DD HH:mm A Z');
      }
    },
    {
      title: 'Provider',
      dataIndex: 'therapistId',
      render: val => {
        return val?.personalInfo?.firstName + ' ' + val?.personalInfo?.lastName;
      }
    },

    {
      title: 'Patient',
      dataIndex: 'fullName'
    },
    {
      title: 'Status',
      dataIndex: 'scheduleInfo',
      render: val => {
        const currentDateTime = new Date();
        const scheduleStart = new Date(val?.start);
        currentDateTime.setUTCSeconds(0);
        currentDateTime.setUTCMilliseconds(0);
        if (currentDateTime.getMinutes() < 30) {
          currentDateTime.setMinutes(0);
        } else {
          currentDateTime.setMinutes(30);
        }
        if (val?.status === 'upcoming' && scheduleStart <= currentDateTime) {
          return 'In progress';
        }
        return val?.status || '';
      }
    },
    {
      title: 'Frequency',
      dataIndex: 'scheduleInfo',
      render: val => {
        return frequencyArray[val?.scheduleFrequency] || '-';
      }
    },
    {
      title: 'Action',
      key: 'action',
      dataIndex: 'scheduleInfo',

      render: val => (
        <>
          <div className='actionIcon'>
            <img
              src={ArrowLeftTable}
              alt=''
              onClick={() => {
                if (val?.status === 'review pending')
                  navigate(`/rate-your-experience/${val?.bookingId}`);
                else navigate(`/appointment-detail/${val?.bookingId}`);
              }}
            />
          </div>
        </>
      )
    }
  ];

  return (
    <>
      <Table
        columns={columns}
        dataSource={data}
        scroll={true}
        pagination={{
          pageSize: 10
        }}
      />
    </>
  );
};
