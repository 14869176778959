import { Input as AntdInput } from 'antd';
import React from 'react';
import './Input.scss';

const Input = ({ ...props }) => {
  return (
    <div className='input-antd-custom'>
      <AntdInput {...props} onWheel={e => e.currentTarget.blur()} />
    </div>
  );
};

export default Input;
