import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  profileData: null
};

const therapistProfileSlice = createSlice({
  name: 'clinicProfileSlice',
  initialState,
  reducers: {
    setProfileStep: (state, action) => {
      state.profileStep = action.payload;
    },
    setProfileData: (state, action) => {
      state.profileData = action.payload;
    },

    setPracticeSoftware: (state, action) => {
      state.profileData.practiceSoftware = action.payload;
    },
    updateProfileField: (state, action) => {
      const { field, value } = action.payload;
      state.profileData[field] = value;
    }
  }
});

export const {
  setProfileStep,
  setProfileData,
  updateProfileField,
  setPracticeSoftware
} = therapistProfileSlice.actions;
export const profileReducer = therapistProfileSlice.reducer;
