import React, { useEffect, useState } from 'react';
import './PsychTopProviders.scss';
import { PsychTopProviderCards } from './PsychTopProviders/PsychTopProviderCards';
import { Button, Col, Row, Spin } from 'antd';
import { BlueLineArrow, OrangeLineArrow } from '../../assets/vectors';
import { getRequest } from '../../api/apiCalls';
import { useNavigate } from 'react-router-dom';
export const PsychTopProviders = () => {
  const navigate = useNavigate();
  const [isLoading, setisLoading] = useState(false);
  const [reviews, setReviews] = useState([]);
  const getReviewsHandler = async () => {
    setisLoading(true);
    const onSuccess = res => {
      setReviews(res?.data);
      setisLoading(false);
    };
    const onError = () => {
      setisLoading(false);
    };
    await getRequest(
      null,
      'customer/get-top-therapists',
      true,
      true,
      onSuccess,
      onError
    );
  };
  useEffect(() => {
    getReviewsHandler();
  }, []);
  return (
    <div className='psychTopProvidersWrapper'>
      <Spin spinning={isLoading}>
        <div className={'psychContainer'}>
          <Row gutter={20}>
            <Col lg={8} md={8} sm={8} xs={24} className={'content-container'}>
              <p className={'title'}>
                Get An <br /> Appointment
              </p>
              <p className={'text'}>
                Get an appointment and embark on a journey toward personal
                growth and well-being. Our expert providers are here to provide
                individualized support, tailored to your unique needs.
              </p>
              <div className={'button-container'}>
                <p onClick={() => navigate('/all-providers')}>
                  Our providers{'  '}
                  <img src={OrangeLineArrow} alt='' className={'l-svg'} />{' '}
                </p>
                {/* <button className='teritaryButton'>Are You A Provider?</button> */}
              </div>
            </Col>
            {reviews?.length ? (
              <Col lg={16} md={16} sm={16} xs={24} className={'card-container'}>
                <PsychTopProviderCards reviews={reviews} />
              </Col>
            ) : null}
          </Row>
        </div>
      </Spin>
    </div>
  );
};
