import dayjs from 'dayjs';
export const disabledFuturesDates = current => {
  // Disable dates that are after today
  return current ? current.isAfter(dayjs().endOf('day')) : false;
};

export const disabledPastDates = current => {
  // Disable dates that are before today
  return current ? current.isBefore(dayjs().startOf('day')) : false;
};
