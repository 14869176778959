import { Checkbox as AntdCheckbox, ConfigProvider } from 'antd';
import './Checkbox.scss';

const Checkbox = ({ ...props }) => {
  const theme = {
    token: {
      colorPrimary: '#6a6fbf'
    }
  };
  return (
    <div className='antd-custom-checkbox-2ndcolor'>
      <ConfigProvider theme={theme}>
        <AntdCheckbox {...props} />
      </ConfigProvider>
    </div>
  );
};

export default Checkbox;
