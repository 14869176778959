import { Select as AntdSelect, ConfigProvider } from 'antd';
import './Select.scss';
const Select = ({ ...props }) => {
  return (
    <div className='antd-custom-select'>
      <ConfigProvider
        theme={{
          token: {
            colorTextPlaceholder: '#998e95'
          }
        }}
      >
        <AntdSelect {...props} />
      </ConfigProvider>
    </div>
  );
};

export default Select;
