import { Form, Modal } from 'antd';
import './SignUp.scss';
import Select from '../../components/UI/Select';
import AntdPassword from '../../components/UI/Password';
import { AuthLayout } from '../../components/layout/AuthLayout';
import AntdInput from '../../components/UI/Input';
import AntdDatePicker from '../../components/UI/DatePicker';
import { useState } from 'react';
import { Disclaimer } from './Disclaimer';
import { disabledFuturesDates } from '../../helpers/dates';

import ButtonGreen from '../../components/UI/Button/ButtonGreen';

export const SignUp = () => {
  const [isDisclaimer, setisDisclaimer] = useState(false);
  const [signupData, setSignupData] = useState(null);

  const onFinish = async values => {
    setSignupData(values);
    setisDisclaimer(true);
  };

  return (
    <>
      <AuthLayout>
        <div className='signup-main-container'>
          <div className='login-main-content'>
            <h3>Sign up</h3>
            {/* <div className='loginImageSide'>
                <LoginSideImage />
              </div> */}
            <Form
              name='basic'
              style={{ width: '100%' }}
              onFinish={onFinish}
              autoComplete='off'
              layout='vertical'
            >
              <Form.Item
                name='fullName'
                rules={[
                  {
                    required: true,
                    message: 'patient/child name is required'
                  }
                ]}
                label='Patient/child name'
              >
                <AntdInput placeholder='Enter patient/child name' />
              </Form.Item>

              <Form.Item
                name={'dob'}
                rules={[{ required: true, message: 'Patient DOB is required' }]}
                label="Patient's date of birth"
              >
                <AntdDatePicker
                  disabledDate={disabledFuturesDates}
                  required
                  placeholder="Enter patient's date of birth"
                />
              </Form.Item>

              <Form.Item
                name='gender'
                rules={[
                  {
                    required: true,
                    message: 'patient/child gender is required'
                  }
                ]}
                label='Patient/child gender'
              >
                <Select
                  placeholder='Select patient/child gender'
                  options={[
                    { label: 'Male', value: 'male' },
                    { label: 'Female', value: 'female' },
                    { label: 'Other', value: 'other' }
                  ]}
                />
              </Form.Item>
              <Form.Item
                name='email'
                rules={[
                  {
                    required: true,
                    message: 'Reachable email address is required'
                  }
                ]}
                label='Reachable email address'
              >
                <AntdInput placeholder='Email Address' type='email' required />
              </Form.Item>

              <Form.Item
                name='relationName'
                // rules={[
                //   {
                //     required: true,
                //     message: 'Parent/Guardian/Representative name is required'
                //   }
                // ]}
                label='Parent/Guardian/Representative name (Optional)'
              >
                <AntdInput placeholder='Enter Parent/Guardian/Representative name' />
              </Form.Item>
              <Form.Item
                name='relationship'
                // rules={[
                //   {
                //     required: true,
                //     message:
                //       'Parent/Guardian/Representative (Relationship to Child) is required'
                //   }
                // ]}
                label='Parent/Guardian/Representative (Relationship to Child)(Optional)'
              >
                <Select
                  placeholder='Select Parent/Guardian/Representative (Relationship to Child)'
                  options={[
                    { label: 'Parent', value: 'parent' },
                    { label: 'Guardian', value: 'guardian' },
                    { label: 'Representative', value: 'representative' }
                  ]}
                />
              </Form.Item>
              <Form.Item
                name='password'
                rules={[
                  {
                    required: true,
                    validator: async (_rule, value) => {
                      if (!value) {
                        return Promise.reject('Password is required');
                      } else if (value.length < 6) {
                        return Promise.reject('Password must be 6 characters');
                      }
                    }
                  }
                ]}
                label='Password'
              >
                <AntdPassword required placeholder='Password' />
              </Form.Item>

              <div className='btn-center-single login-button'>
                <ButtonGreen text={'Sign Up'} type='submit' />
              </div>
            </Form>
          </div>
        </div>
      </AuthLayout>
      <Modal
        open={isDisclaimer}
        centered={true}
        onCancel={() => setisDisclaimer(false)}
      >
        {' '}
        <Disclaimer data={signupData} setModal={setisDisclaimer} />
      </Modal>
    </>
  );
};
