import CollapsePanel from 'antd/es/collapse/CollapsePanel';
import React, { useState } from 'react';
import { FaqsMinus, FaqsPlus } from '../../assets/vectors';
import { Collapse } from 'antd';
import './PsychCategories.scss';
export const PsychCategories = ({ categories }) => {
  console.log(categories);
  const items = categories?.map((item, index) => ({
    key: index?.toString(),
    label: item?.categoryName,
    children: (
      <div className='categoriesCollapseContent'>
        <img src={item?.icon} alt='' />
        <p style={{ padding: '0 16px' }}>{item?.description}</p>
      </div>
    )
  }));

  return (
    <div className={'psychCategoriesWrapper'}>
      <div className={'psychContainer'}>
        <h2 className='heading'>Provider service you need...</h2>

        <Collapse
          className='customFaqsCollapse'
          defaultActiveKey={['0']}
          ghost
          items={items}
          expandIcon={({ isActive }) =>
            isActive ? (
              <img src={FaqsMinus} alt='-' />
            ) : (
              <img src={FaqsPlus} alt='+' />
            )
          }
        />
      </div>
    </div>
  );
};
