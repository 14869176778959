import React, { useEffect, useState } from 'react';
import './header.scss';
import { Footer_logo_Dark, Logo } from '../../assets/vectors';
import { Link, useNavigate } from 'react-router-dom';
import { Drawer, Dropdown } from 'antd';
import Sticky from 'react-stickynode';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../Redux/Reducers/authSlice';
import { DP, WarningSign } from '../../assets/images';
import Swal from 'sweetalert2';
import { setProfileData } from '../../Redux/Reducers/clinicProfileSlice';
import { getRequest } from '../../api/apiCalls';
import ButtonPurple from '../UI/Button/ButtonPurple';
import { MenuOutlined } from '@ant-design/icons';
import { useMediaQuery } from 'react-responsive';

const Header = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = useSelector(state => state.authReducer.token);
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' }); // Media query hook for detecting mobile view
  const baseURL = window.location.origin;

  const [open, setOpen] = useState(false);
  const profileImg = useSelector(
    state => state?.profile?.profileData?.profile_image
  );
  const onClose = () => {
    setOpen(false);
  };
  const handleLogout = () => {
    Swal.fire({
      title: 'Are you sure you want to log out?',
      iconHtml: `<img src="${WarningSign}" alt="Warning Sign" />`,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Logout'
    }).then(result => {
      if (result.isConfirmed) {
        localStorage.removeItem('token');
        dispatch(logout());
        navigate('/login');
      }
    });
  };
  const getAllData = async () => {
    const onSuccess = res => {
      dispatch(setProfileData(res?.detail));
    };
    const onError = res => {
      console.log(res, 'erorr res');
      dispatch(setProfileData(null));
    };
    await getRequest(null, 'auth/me', true, true, onSuccess, onError);
  };
  useEffect(() => {
    if (token) {
      getAllData();
    }
  }, []);
  const items = [
    {
      label: (
        <Link to='/account'>
          <p className='customDropdownButtons'>My account</p>
        </Link>
      ),
      key: '0'
    },
    {
      label: (
        <Link to='/consultations'>
          <p className='customDropdownButtons'>My consultations</p>
        </Link>
      ),
      key: '1'
    },
    {
      label: (
        <Link to='/notification'>
          <p className='customDropdownButtons'>Notifications</p>
        </Link>
      ),
      key: '2'
    },
    {
      label: (
        <p
          className='customDropdownButtons logout-button'
          onClick={handleLogout}
        >
          Logout
        </p>
      ),
      key: '3'
    }
  ];
  return (
    <>
      <Sticky top={0} innerZ={100}>
        <div className='header'>
          <div className='logo' onClick={() => navigate('/')}>
            <img src={Logo} alt='' onClick={() => navigate('/')} />
          </div>
          <div className='headerMenuWrapper '>
            {token ? (
              <div className='profilePic'>
                <div className='headerMenu'>
                  {!isMobile ? (
                    <>
                      <ButtonPurple
                        text={'Request an appointment'}
                        onClick={() => navigate('/all-providers')}
                      />
                      <ButtonPurple
                        text={'Join as Provider'}
                        onClick={() =>
                          (window.location.href = baseURL + '/therapist')
                        }
                      />
                    </>
                  ) : null}
                </div>
                <Dropdown
                  menu={{ items }}
                  trigger={['click']}
                  className='dropdown customHeaderDrop'
                >
                  {profileImg ? (
                    <img
                      className='headerUserProfileImage'
                      src={profileImg}
                      alt=''
                    />
                  ) : (
                    <div className='headerProfileDummyImg'>
                      <DP />
                    </div>
                  )}
                </Dropdown>
              </div>
            ) : (
              <>
                <div className='headerMenu'>
                  {!isMobile ? (
                    <>
                      <ButtonPurple
                        text={'Request an appointment'}
                        onClick={() => navigate('/all-providers')}
                      />{' '}
                      <ButtonPurple
                        text={'Join as Provider'}
                        onClick={() =>
                          (window.location.href = baseURL + '/therapist')
                        }
                      />
                    </>
                  ) : null}
                  <MenuOutlined onClick={() => setOpen(true)} />
                </div>
              </>
            )}
          </div>
        </div>
      </Sticky>
      <Drawer
        title={
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <img src={Footer_logo_Dark} alt='$$$' />
          </div>
        }
        placement={'right'}
        closable={false}
        onClose={onClose}
        open={open}
        key={'right'}
      >
        <ButtonPurple
          style={{ width: '100%', marginBottom: '20px' }}
          text={'Login'}
          onClick={() => navigate('/login')}
        />{' '}
        <ButtonPurple
          style={{ width: '100%', marginBottom: '20px' }}
          text={'Sign Up'}
          onClick={() => navigate('/signup')}
        />{' '}
        <ButtonPurple
          style={{ width: '100%', marginBottom: '20px' }}
          text={'Request an appointment'}
          onClick={() => navigate('/all-providers')}
        />{' '}
        <ButtonPurple
          style={{ width: '100%', marginBottom: '20px' }}
          text={'Join as Provider'}
          onClick={() => (window.location.href = baseURL + '/therapist')}
        />
      </Drawer>
    </>
  );
};

export default Header;
