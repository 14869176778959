import React, { useEffect, useState } from 'react';
import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';
import { getRequest } from '../../api/apiCalls';
import { PsychTopProviderCards } from '../../components/landingPage/PsychTopProviders/PsychTopProviderCards';
import './AllProviders.scss';
import { Empty, Spin } from 'antd';
export const AllProviders = () => {
  const [isLoading, setisLoading] = useState(false);
  const [reviews, setReviews] = useState([]);
  const getReviewsHandler = async () => {
    setisLoading(true);
    const onSuccess = res => {
      setReviews(res?.data);
      setisLoading(false);
    };
    const onError = () => {
      setisLoading(false);
    };
    await getRequest(
      null,
      'therapist/get-all-therapist',
      true,
      true,
      onSuccess,
      onError
    );
  };
  useEffect(() => {
    getReviewsHandler();
  }, []);
  return (
    <div>
      <Header />
      <div className='allProvidersWrapper'>
        <h2>Our Providers</h2>
        <Spin spinning={isLoading}>
          {reviews?.length ? (
            <div className='psychContainer'>
              <PsychTopProviderCards reviews={reviews} isAllProviders />
            </div>
          ) : (
            <Empty />
          )}
        </Spin>
      </div>
      <Footer />
    </div>
  );
};
