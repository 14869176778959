import { Input } from "antd";
import "./TextArea.scss";
const { TextArea: AntdTextArea } = Input;
const TextArea = ({ ...props }) => {
  return (
    <div className="antd-custom-textarea">
      <AntdTextArea {...props} />
    </div>
  );
};

export default TextArea;
