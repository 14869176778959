import axios from 'axios';
import { BASE_URL } from '../constants';
import { message } from 'antd';

function getUrl(route, baseurl) {
  if (!baseurl) {
    return route;
  } else {
    return `${BASE_URL}/${route}`;
  }
}
function getToken() {
  const token = localStorage.getItem('token');
  return token;
}
const apiCall = async (
  method,
  payload,
  route,
  baseurl,
  onSuccess,
  onError,
  isTokenReq
) => {
  try {
    const url = getUrl(route, baseurl);
    let response = null;
    const token = getToken();
    let config = {
      method: method,
      maxBodyLength: Infinity,
      data: payload,
      url: url,
      headers: token && isTokenReq ? `x-sh-auth: ${token}` : null
    };

    response = await axios.request(config);
    onSuccess(response.data);
    if (method !== 'get' && response?.data?.message) {
      message.success(response?.data?.message);
    }
    return { status: 200, response: response.data };
  } catch (e) {
    onError(e.response?.data);
    if (method !== 'get' && e.response?.data?.message) {
      message.error(e.response?.data?.message);
    }
    return {
      status: 400,
      response: e?.response?.data
        ? e?.response?.data
        : { message: e.toString() }
    };
  }
};
export const getRequest = async (
  payload,
  route,
  baseurl,
  isTokenReq = true,
  onSuccess = res => {},
  onError = err => {},
  stopLoader = true
) => {
  await apiCall(
    'get',
    payload,
    route,
    baseurl,
    onSuccess,
    onError,
    stopLoader,
    isTokenReq
  );
};
export const postRequest = async (
  payload,
  route,
  baseurl,
  isTokenReq = true,
  onSuccess = res => {},
  onError = err => {},
  stopLoader = true
) => {
  await apiCall(
    'post',
    payload,
    route,
    baseurl,
    onSuccess,
    onError,
    stopLoader,
    isTokenReq
  );
};
export const patchRequest = async (
  payload,
  route,
  baseurl,
  isTokenReq = true,
  onSuccess = res => {},
  onError = err => {},
  stopLoader = true
) => {
  await apiCall(
    'patch',
    payload,
    route,
    baseurl,
    onSuccess,
    onError,
    stopLoader,
    isTokenReq
  );
};
export const putRequest = async (
  payload,
  route,
  baseurl,
  isTokenReq = true,
  onSuccess = () => {},
  onError = () => {},
  stopLoader = true
) => {
  await apiCall(
    'put',
    payload,
    route,
    baseurl,
    onSuccess,
    onError,
    stopLoader,
    isTokenReq
  );
};
export const deleteRequest = async (
  payload,
  route,
  baseurl,
  isTokenReq = true,
  onSuccess = res => {},
  onError = err => {}
) => {
  await apiCall(
    'delete',
    payload,
    route,
    baseurl,
    onSuccess,
    onError,

    isTokenReq
  );
};
