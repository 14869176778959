import React from 'react';
import { DP } from '../../assets/images';
import { RightOutlined, UserOutlined } from '@ant-design/icons';
import {
  AgeGroupIcon,
  BrainImg,
  CalendarXmark,
  HospitalUserRegular,
  Location
} from '../../assets/vectors';
import { ScheduleCalender } from './ScheduleCalender';
import ButtonGreen from '../../components/UI/Button/ButtonGreen';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

export const SingleDoctorCard = ({
  data,

  setWaitlistActiveThearapist,
  setOpenModal
}) => {
  console.log(data);
  const navigate = useNavigate();
  const token = useSelector(state => state.authReducer.token);
  return (
    <div className='card_slot'>
      <div className='left'>
        <div className='first__div'>
          <div className='imageContainer'>
            {data?.profileImg ? <img src={data?.profileImg} alt='' /> : <DP />}
          </div>
          <div className='name__stars'>
            <h4>{data?.name}</h4>
            <button
              className='customNoBg'
              onClick={() => navigate(`/doctor-information/${data?._id}`)}
            >
              Read More <RightOutlined />
            </button>
          </div>
        </div>
        <div className='third__div'>
          <img src={BrainImg} alt='' className='brainImg' />
          <div className='secound__div__content'>
            <p className='para_heading'>
              {data?.practice?.map(category => category.name)?.join(', ')}
            </p>
            <p className='para_title'>
              {data?.practice
                ?.flatMap(category =>
                  category.practiceSpecialties.map(
                    subCategory => subCategory.name
                  )
                )
                ?.join(', ')}
            </p>
          </div>
        </div>
        {data?.geoLocations?.map((elem, ind) => (
          <div className='third__div'>
            <img src={Location} alt='' className='location' />
            <div className='secound__div__content'>
              <p className='para_heading'>{elem?.place}</p>
            </div>
          </div>
        ))}

        <div className='third__div'>
          <img src={AgeGroupIcon} alt='' />
          <div className='secound__div__content'>
            <p className='para_heading'>
              {data?.providerProfile?.ageGroup
                ?.map(group => (group === '0' ? 'Infants' : group))
                .join(', ') || '-'}
            </p>
          </div>
        </div>
        <div className='third__div'>
          <UserOutlined />
          <div className='secound__div__content'>
            <p className='para_heading'>{data?.personalInfo?.bio}</p>
          </div>
        </div>
        {data?.clinicId ? (
          <div className='third__div'>
            <img src={HospitalUserRegular} alt='' className='location' />
            <div className='secound__div__content'>
              <p className='para_heading'>
                Associated with <b>{data?.clinicInfo?.clinicName}</b>
              </p>
            </div>
          </div>
        ) : null}

        {/* <Button
    onClick={() => {
      dispatch(resetAppointmentState());
      navigate(`/make-appointment/${data?._id}`);
    }}
    text='Make an appointment'
  /> */}
      </div>
      <div className='right'>
        {data?.schedules.length ? (
          <ScheduleCalender data={data} />
        ) : (
          <>
            <div className='emptySchedules'>
              <span>
                <img src={CalendarXmark} alt='$$$' />
                <p> No bookable appointment available</p>
              </span>
              <ButtonGreen
                text='Join waitlist'
                className='Make_an_appointment'
                onClick={() => {
                  if (token) {
                    setWaitlistActiveThearapist(data?._id);
                    setOpenModal(true);
                  } else {
                    navigate('/login');
                  }
                }}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};
