import './ButtonGreen.scss';
import React from 'react';

const ButtonGreen = ({ text, className, ...props }) => {
  return (
    <button className={`primary-btn-green ${className}`} {...props}>
      {text}
    </button>
  );
};

export default ButtonGreen;
