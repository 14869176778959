import { ConfigProvider, DatePicker } from 'antd';
import './DatePicker.scss';
const AntdDatePicker = ({ ...props }) => {
  return (
    <div className='antd-picker-custom'>
      <ConfigProvider
        theme={{
          token: {
            colorTextPlaceholder: '#998e95'
          }
        }}
      >
        <DatePicker format='DD/MM/YYYY' {...props} changeOnBlur={true} />
      </ConfigProvider>
    </div>
  );
};

export default AntdDatePicker;
