import { Col, Row } from 'antd';
import React from 'react';
import { BlueLineArrow, JoinProviderImage } from '../../assets/vectors';
import './PsychJoinProvider.scss';
import ButtonPurple from '../UI/Button/ButtonPurple';
export const PsychJoinProvider = () => {
  const baseURL = window.location.origin;

  const providerBenefits = [
    "No contracts, enjoy full benefits for free for the first 2 months and then it's $25/month",
    'Establish care with the many patients looking for providers on Psych Now platform',
    'Rank higher in google searches and build your practice',
    'Track data to see your progress'
  ];
  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
          marginTop: '30px'
        }}
      >
        <ButtonPurple
          text='Join us'
          className='largePurpleButton'
          onClick={() => (window.location.href = `${baseURL}/therapist/signup`)}
        />
      </div>
      <div className='psychJoinProviderWrapper'>
        <div className={'psychContainer'}>
          <Row gutter={10} style={{ alignItems: 'center' }}>
            <Col lg={12} md={12} sm={24} xs={24}>
              <img
                style={{ maxWidth: '100%' }}
                src={JoinProviderImage}
                alt='$$$'
              />
            </Col>
            <Col
              lg={12}
              md={12}
              sm={24}
              xs={24}
              className='joinProviderContentWrap'
            >
              <div className='heading'>Are you A Provider?</div>
              <div className='list'>
                {providerBenefits?.map((elem, ind) => (
                  <div className='item' key={ind}>
                    <img src={BlueLineArrow} alt='' className={'l-svg'} />{' '}
                    <p>{elem}</p>
                  </div>
                ))}
              </div>

              <a
                className='teritaryButton'
                href={`${baseURL}/therapist/signup`}
              >
                Join Us{' '}
              </a>
              {/* <button >Join Us</button> */}
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};
