export const privacyPolicyArray = [
  {
    heading: "Information We Collect",
    content: [
      {
        text: "We collect personally identifiable information from you through various interactions on our website, including:",
        innerContent: [
          {
            text: `Posts and Comments: When you post a comment or send us an email, we collect information like your name and email address.`,
          },
          {
            text: `Emails to Professionals or Friends: If you email a professional listed on our site or a friend about a professional, we collect your name and email address, as well as your friend's name and email address. We do not read or retain the content of your emails.`,
          },
          {
            text: `  Phone Calls to Professionals: We collect information about the origin of calls made to professionals listed on our site.
            `,
          },
        ],
      },
      {
        text: "www.psychnow.com.au is a website (“Website”) operated by Psych Now trading as Psych Now ABN 81 504 557 256 (we, us and our).",
      },
      {
        text: "The purpose of the Website is to facilitate the booking and managing of medical, allied health and healthcare appointments between you and Practitioners (“Appointments”).  ",
      },
      {
        text: "You consent to us collecting, holding, using and disclosing your personal information in accordance with this policy. You further consent to us sharing your personal information with any Practitioner that you make an Appointment with. ",
      },
      {
        text: "‘Practitioner’ means either a client such as a medical practice, healthcare practice, allied health practice or an individual therapist that provides medical and healthcare services and has access to and use of the Website for the purpose of making and managing Appointments.",
      },
    ],
  },
  {
    heading: "What is personal information?",
    content: [
      {
        text: "The collection, use and disclosure of personal information is governed by the Privacy Act 1988 (Cth) (Privacy Act) and the Australian Privacy Principles (APPs). ",
      },
      {
        text: "Personal information is any information or an opinion about an identified individual or an individual who can be reasonably identified from the information or opinion. Information or an opinion may be personal information regardless of whether it is true.",
      },
    ],
  },
  {
    heading: "What personal information do we collect and hold?",
    content: [
      {
        text: "We may collect personal information from you, such as your name, email address, address, phone number, and other contact information, when you visit our website or use our services or purchase our products (where applicable).  We may also collect information about your usage of our website or services through the use of cookies or other tracking technologies.",
      },
      {
        text: "We may collect information about how you access, use and interact with the website. We do this by using a range of tools such as Google Analytics. This information may include:",
        innerContent: [
          {
            text: "The location from which you have come to the site and the pages you have visited; and",
          },
          {
            text: "Technical data, which may include IP address, the types of devices you are using to access the website, device attributes, browser type, language and operating system.",
          },
        ],
      },
      {
        text: "We use cookies on the website. A cookie is a small text file that the website may place on your device to store information. We may use persistent cookies (which remain on your computer even after you close your browser) to store information that may speed up your use of our website for any of your future visits to the website. We may also use session cookies (which no longer remain after you end your browsing session) to help manage the display and presentation of information on the website. You may refuse to use cookies by selecting the appropriate settings on your browser.  However, please note that if you do this, you may not be able to use the full functionality of the website.",
      },
    ],
  },
  {
    heading: "Why do we collect, hold and use your personal information?",
    content: [
      {
        text: "We collect, hold and use your personal information so that we can:",
        innerContent: [
          {
            text: "Provide you with products and services, and manage our relationship with you;",
          },
          {
            text: "Share information with the medical or healthcare practitioner you want to make an Appointment with;",
          },
          {
            text: "Contact you, for example, to respond to your queries or complaints, or if we need to tell you something important;",
          },
          {
            text: "Developing and growing our business and services (such as identifying client needs and improvements in service delivery);",
          },
          {
            text: "Maintaining, administering and managing our website and systems and applications;",
          },
          {
            text: "Comply with our legal obligations and assist government and law enforcement agencies or regulators; or",
          },
          {
            text: "Identify and tell you about other products or services that we think may be of interest to you. ",
          },
        ],
      },
      {
        text: "If you do not provide us with your personal information, we may not be able to provide you with our services, communicate with you or respond to your enquiries.",
      },
    ],
  },
  {
    heading: "How do we collect your personal information?",
    content: [
      {
        text: "We will collect your personal information directly from you whenever you interact with us. ",
      },
    ],
  },
  {
    heading: "How do we store and hold personal information?",
    content: [
      {
        text: "The security of your personal information is extremely important to us. To prevent any unauthorised access or disclosure, we have implemented appropriate physical, electronic, and managerial security measures. These procedures aim to safeguard and secure personal information, protecting it from misuse, interference, loss, unauthorised access, modification, and disclosure.",
      },
      {
        text: "Please note that we cannot guarantee the security of any information transmitted to or from us over the internet. The transmission and exchange of information is at your own risk. While we take reasonable precautions to prevent unauthorized disclosures, we cannot provide absolute assurance that the personal information we collect will never be disclosed in a manner inconsistent with this Privacy Policy.",
      },
    ],
  },
  {
    heading: "Sensitive Personal Data",
    content: [
      {
        text: "Psych Now confirms that it does not collect, hold, or require any sensitive personal data from its users, and that you should refrain from providing any sensitive personal data to us. Sensitive personal data includes health information. This should not be provided to us and should only be given to the Practitioner in your Appointment. We are committed to protecting your privacy and ensuring the security of your personal information. If you have any concerns or questions regarding the collection and use of your personal information, please contact us using the details provided in this Privacy Policy.",
      },
    ],
  },
  {
    heading: "Who do we disclose your personal information to, and why? ",
    content: [
      {
        text: "We will never sell, rent or disclose your personal information to third parties without your consent, unless required by law or permitted by the Privacy Act. We may share your personal information with:",
        innerContent: [
          {
            text: "the medical and healthcare practitioners that you chose to make an Appointment with;",
          },
          {
            text: "our employees or contractors;",
          },
          {
            text: "our business partners and affiliates; and ",
          },

          {
            text: "our trusted third-party service providers who assist us in providing our goods or services to you, such as payment processors, debt collectors, marketing agencies or website hosting providers. ",
          },
        ],
      },
      {
        text: "If the ownership or control of all or part of our business changes, we may transfer your personal information to the new owner. ",
      },
      {
        text: "We may also disclose your personal information to courts, tribunals and regulatory authorities, if:    ",
        innerContent: [
          {
            text: "you fail to pay for services we have provided to you;            ",
          },
          {
            text: "in connection with any actual or prospective legal proceedings;",
          },
          {
            text: "in order to establish, exercise or defend our legal rights; or ",
          },
          {
            text: "as required by law.  ",
          },
        ],
      },
    ],
  },
  {
    heading: "Do we disclose personal information to overseas recipients? ",
    content: [
      {
        text: "Personal information may be stored overseas, including with our IT development team in Pakistan. If we share your personal information with the third parties listed above, they may also store, transfer, or access personal information outside of Australia. We will only disclose your personal information to third parties in countries that have laws protecting personal information in a manner similar to the Australian Privacy Principles, unless we have obtained your consent for the overseas disclosure of your personal information. Alternatively, we will take reasonable steps, considering the circumstances, to ensure that overseas recipients protect your personal information in accordance with the Australian Privacy Principles.",
      },
      {
        text: "You expressly consent to the transfer of your personal information to our IT development team in Pakistan for the purposes of building and administering our IT infrastructure and systems.  ",
      },
    ],
  },
  {
    heading: "Do we use your personal information for marketing?",
    content: [
      {
        text: "We want to make sure that we provide you with products and services that you'll love, so we may use your personal information to offer you products and services that we think might interest you. However, if you prefer not to receive these offers, just let us know and we won't send them to you. These offers may come from us, our related companies, our business partners, or our service providers.",
      },
      {
        text: "If you receive marketing emails from us, you can easily opt-out of receiving further emails by following the instructions provided in the email.",
      },
    ],
  },
  {
    heading: "Benchmarking ",
    content: [
      {
        text: "The parties acknowledge and agree that Psych Now may collect, use, and disclose anonymized data for the purpose of conducting benchmarking activities. Anonymized data refers to information that has been stripped of any personally identifiable elements and cannot be used to identify any individual.",
      },
      {
        text: "By using Psych Now's website, services, or products, you expressly consent to the collection, use, and disclosure of anonymized data for benchmarking purposes as described in this clause.",
      },
      {
        text: "The purpose of benchmarking is to analyse and compare aggregated data to identify trends, patterns, and insights in the field of therapy and mental health services. The benchmarking activities aim to improve the quality and effectiveness of Psych Now's services and products.",
      },
      {
        text: "Psych Now shall not attempt to re-identify any individuals from the anonymized data collected for benchmarking purposes. Any attempt to re-identify individuals or misuse the anonymized data shall be considered a breach of this agreement.",
      },
      {
        text: "The parties acknowledge that Psych Now shall own all rights, title, and interest in the anonymized data collected for benchmarking purposes. Nothing in this clause shall be construed as transferring any ownership rights in the anonymized data to any third party.",
      },
    ],
  },
  {
    heading: "Access to and correction of your personal information",
    content: [
      {
        text: "You have the right to access and correct any personal information we hold about you. If you want to do so, just contact us using the details provided below. However, there may be some situations where we're not able to give you access to your information.",
      },
      {
        text: "We won't charge you for requesting access to your information, but if your request requires us to do a lot of work, such as photocopying or compiling a lot of material, we may need to pass on some reasonable costs to you.",
      },
      {
        text: "We will endeavour to respond to your request to access or correct your personal information as quickly as we can. We will also do our best to make sure that the information we hold about you is accurate and up-to-date.",
      },
    ],
  },
  {
    heading: "Complaints",
    content: [
      {
        text: "We take your privacy seriously, and we want to make sure that we're always doing the right thing by you. If you have a complaint about the way we've handled your personal information or you have any other privacy-related concerns, please get in touch with us using the contact details below.",
      },
      {
        text: "We promise to listen to your complaint and investigate it thoroughly. We'll keep you updated on the outcome of our investigation and any subsequent internal investigations.",
      },
      {
        text: "If you're still not satisfied with the way we've handled your privacy issue, you can reach out to an independent advisor for guidance. Alternatively, you can contact the Office of the Australian Information Commissioner (OAIC) for advice on other steps you can take. You can find their website at www.oaic.gov.au.",
      },
      {
        text: "At the end of the day, we're committed to resolving any privacy issues quickly and to your satisfaction. So please don't hesitate to contact us if you need to.",
      },
    ],
  },
  {
    heading: "Contact details",
    content: [
      {
        text: "If you have any questions, comments, requests or concerns, please contact us at:        ",
      },
      {
        text: "Psych Now trading as Psych Now ABN 81 504 557 256         ",
      },
      {
        text: "Email: hello@psychnow.com.au   ",
      },
      {
        text: "      Last updated: 16 September 2023 ",
      },
    ],
  },
  {
    heading: "Changes to this policy ",
    content: [
      {
        text: "We may update this privacy policy from time to time to reflect changes in our practices or legal obligations. We encourage you to review this privacy policy periodically to stay informed about how we are protecting your personal information.",
      },
      {
        text: "If you have any questions or concerns about our privacy policy or how we use your personal information, please don't hesitate to contact us. We're always happy to help!",
      },
    ],
  },
];

 

// content = [
//   {
//     text: 'hello',
//     innerContent: [
//       {
//         text: 'world',
//         subInnerContent: [
//           {
//             text: 'boom'
//           }
//         ]
//       }
//     ]
//   }
// ];




export const newPrivacyPolicyArray = [
  {
    heading: "Information We Collect",
    content: [
      {
        text: "We collect personally identifiable information from you through various interactions on our website, including:",
        innerContent: [
          {
            text: "Posts and Comments: When you post a comment or send us an email, we collect information like your name and email address.",
          },
          {
            text: "Emails to Professionals or Friends: If you email a professional listed on our site or a friend about a professional, we collect your name and email address, as well as your friend's name and email address. We do not read or retain the content of your emails.",
          },
          {
            text: "Phone Calls to Professionals: We collect information about the origin of calls made to professionals listed on our site.",
          },
          {
            text: "Self-Assessments and Tests: When you complete a self-assessment or test administered by third parties, their privacy policies govern the information they collect. Please refer to their policies via the links on the assessment and test pages.",
          },
          {
            text: "Subscriptions: We collect your name and address when you subscribe to a publication.",
          },
          {
            text: "Purchases: Payment information is submitted directly to our credit card processor, who manages its use and security.",
          },
          {
            text: "Directory Registrations: Information collected for directory listings includes your name, address, email, qualifications, certifications, geographic area, and specialties. Additional information may be collected for verification and payment purposes.",
          },
          {
            text: "Email Tracking: We use 'web beacons' to track which emails have been opened, helping us evaluate the effectiveness of our marketing efforts.",
          },
        ],
      },
      {
        text: "We also collect site usage data, including user interest levels, page views, time spent on the site, and web browsers used. This data is tracked in aggregate and is not personally identifiable. Aggregate data may be shared with third parties and the public.",
      },
    ],
  },
  {
    heading: "How We Use Your Information",
    content: [
      {
        text: "We use the information collected to provide you with the services you request on our website.",
      },
      {
        text: "Information Sharing: We only share information with third parties as necessary to deliver the products or services you request. For example, we share subscriber information with our mailing company and purchase information with our credit card processor. Information from professionals may be verified with licensing boards. We do not share or sell personally identifiable information about our visitors otherwise.",
      },
      {
        text: "Cookies: We use cookies to enhance your web experience. Cookies do not reveal personal information unless you provide it. If your browser does not accept cookies, some website features may not function properly.",
      },
      {
        text: "Third-Party Advertising: We allow third-party advertising companies to display ads when you visit our website. These companies may use information about your visits to this and other sites (excluding personal identifiers) to provide relevant advertisements. For more information, click here.",
      },
      {
        text: "Third-Party Links: When you click links or ad banners that lead to third-party websites, those sites may collect information about you. Their privacy policies may differ from ours, and we encourage you to read their privacy statements.",
      },
      {
        text: "Opt-Out Rights: You have the right to opt out of sharing or selling your personal information for targeted advertising and certain third-party data processing, in compliance with privacy laws. To opt-out, click the 'Do not sell or share' button at the bottom of this page.",
      },
    ],
  },
  {
    heading: "Public Materials",
    content: [
      {
        text: "Materials you post, such as articles, comments, or directory information, are publicly accessible. We can remove your materials at your request but cannot ensure their removal from external servers or downloads by others.",
      },
    ],
  },
  {
    heading: "Marketing Lists",
    content: [
      {
        text: "We may use third-party marketing lists to send direct marketing promotions. We strive to use lists from suppliers compliant with applicable laws. Even if you opt-out of our promotions, you may still receive them if provided by a third-party list. To stop receiving such promotions, contact us via our feedback page.",
      },
    ],
  },
  {
    heading: "Limitations",
    content: [
      {
        text: "We aim to protect your privacy but cannot guarantee it. We may disclose personal information as required by law or in good faith to comply with legal processes or law enforcement requests.",
      },
    ],
  },
  {
    heading: "Data Access and Erasure",
    content: [
      {
        text: "In some jurisdictions, you can request copies or deletion of your personal information, subject to legal exceptions. To exercise your rights, email mailto:support@psychnoww.com with the subject line 'Personal Data Request,' including sufficient information to verify your identity and understand your request. We will respond within the mandated time frame.",
      },
    ],
  },
  {
    heading: "Amendments",
    content: [
      {
        text: "We reserve the right to modify this policy according to the amendment policy in our Terms of Use.",
      },
    ],
  },
];
