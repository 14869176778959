import { Form } from 'antd';
import AntdInput from '../../components/UI/Input';
import AntdPassword from '../../components/UI/Password';
import './Login.scss';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  setEmail,
  setToken,
  setUser,
  updateAuthField
} from '../../Redux/Reducers/authSlice';
import { postRequest } from '../../api/apiCalls';
import { useState } from 'react';
import { AuthLayout } from '../../components/layout/AuthLayout';
import ButtonGreen from '../../components/UI/Button/ButtonGreen';

export const Login = () => {
  const [isLoading, setIsLoading] = useState(false);
  const selected_appointment = useSelector(state => state.appointment);
  const searchedParems = useSelector(
    state => state.searchedTherapists.therapists
  );

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const onFinish = async values => {
    setIsLoading(true);
    values.role = 'customer';
    const onSuccess = res => {
      const userLoginData = {
        email: res?.data.email,
        role: res?.data.role,
        userId: res?.data.userId
      };
      dispatch(setUser(userLoginData));
      dispatch(setToken(res?.data.token));
      dispatch(
        updateAuthField({
          field: 'isProfileFilled',
          value: res?.data?.isProfileFilled
        })
      );
      localStorage.setItem('token', res?.data?.token);
      setIsLoading(false);
      if (
        selected_appointment?.selected_slot &&
        selected_appointment?.selected_therapist
      ) {
        navigate(
          `/make-appointment/${selected_appointment?.selected_therapist}`
        );
      } else if (searchedParems && searchedParems.length) {
        navigate('/search-therapist');
      } else {
        navigate('/');
      }
    };
    const onError = err => {
      setIsLoading(false);
      if (err?.code === 450) {
        dispatch(setEmail(values?.email));
        navigate('/signup/verify');
      }
    };
    await postRequest(values, 'auth/login', true, false, onSuccess, onError);
  };

  const onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo);
  };
  return (
    <AuthLayout>
      <div className='login-main-container'>
        <div className='login-main-content'>
          <h3>Login</h3>
          <Form
            name='basic'
            style={{ width: '100%' }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete='off'
            layout='vertical'
          >
            <Form.Item
              name='email'
              rules={[{ required: true, message: 'Email Address is required' }]}
              label='Email Address'
            >
              <AntdInput placeholder='Email Address' type='email' required />
            </Form.Item>
            <Form.Item
              name='password'
              rules={[{ required: true, message: 'Password is required' }]}
              label='Password'
            >
              <AntdPassword placeholder='Password' required />
            </Form.Item>
            <div className='forget-password'>
              <p
                onClick={() => {
                  navigate('/forget-password');
                }}
              >
                Forgot password?
              </p>
            </div>
            <div className='btn-center-single login-button'>
              <ButtonGreen text={'Log in'} type='submit' disabled={isLoading} />
            </div>
          </Form>
        </div>
      </div>
    </AuthLayout>
  );
};
