import React, { useEffect, useState } from 'react';
import { getRequest } from '../../api/apiCalls';
import { setFilterValues } from '../../Redux/Reducers/searchedTherapistsSlice';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Chevron } from '../../assets/vectors';
import Footer from '../../components/footer/Footer';
import Header from '../../components/header/Header';
import './AllCategories.scss';
import SpinLoader from '../../components/UI/Loader';
export const AllCategories = () => {
  const [categories, setCategories] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const getCategoriesHandler = async () => {
    setisLoading(true);
    const onSuccess = res => {
      setCategories(res?.data);
      setisLoading(false);
    };
    const onError = () => {
      setisLoading(false);
    };
    await getRequest(
      null,
      'category/get-category',
      true,
      false,
      onSuccess,
      onError
    );
  };
  useEffect(() => {
    getCategoriesHandler();
  }, []);
  return (
    <>
      {' '}
      <Header />
      {isLoading ? (
        <SpinLoader />
      ) : (
        <div className='search_category '>
          <h1>Specialities</h1>
          <div className='category_content' style={{ marginBottom: '30px' }}>
            <div className='boxes'>
              {categories?.map(el => (
                <div
                  className='box'
                  onClick={() => {
                    const category = {
                      label: el?.categoryName,
                      value: el._id
                    };
                    dispatch(
                      setFilterValues({
                        location: '',
                        lat: '',
                        lng: '',
                        name: '',
                        category: category || {}
                      })
                    );
                    navigate(`/search-therapist/?category=${el?._id}`);
                  }}
                >
                  <div className='box_left'>
                    <img src={el?.icon} alt='' />
                    <h4> {el?.categoryName} </h4>
                  </div>
                  <img src={Chevron} alt='' />
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
      <Footer color={true} />
    </>
  );
};
