import React from 'react';
import { ConfigProvider } from 'antd';
import './assets/sass/main.scss';
import { AppRoutes } from './routes';

const App = () => {
  const antdConfiguration = {
    token: {
      fontFamily: 'Nunito Sans',
      colorLink: '#3d8aff',
      controlOutline: '#d4cfcc',
      colorText: '#1d1e25',
      colorTextBase: '#1d1e25',
      borderRadius: '7px'
      // colorBorder: "none",
    }
  };

  return (
    <>
      <ConfigProvider theme={antdConfiguration}>
        <AppRoutes />
      </ConfigProvider>
    </>
  );
};

export default App;
