import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  appointment_type: '',
  user_address: '',
  selected_therapist: '',

  selected_slot: null
};
const appointmentSlice = createSlice({
  name: 'appointment',
  initialState: initialState,
  reducers: {
    updateAppointmentField: (state, action) => {
      const { field, value } = action.payload;
      state[field] = value;
    },
    resetAppointmentState: state => {
      return initialState;
    }
  }
});

export const { updateAppointmentField, resetAppointmentState } =
  appointmentSlice.actions;
export const appointmentReducer = appointmentSlice.reducer;
