import './ButtonPurple.scss';
import React from 'react';

const ButtonPurple = ({ text, className, ...props }) => {
  return (
    <button className={`primary-btn-purple ${className}`} {...props}>
      {text}
    </button>
  );
};

export default ButtonPurple;
