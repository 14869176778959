import React from 'react';
import { PsychHeroBanner } from '../../assets/images';
import './PsychBanner.scss';
import { Col, Row } from 'antd';
import { PsychSearchTherapist } from './PsychSearchTherapist';

export const PsychBanner = ({ setisLoading }) => {
  return (
    <div className={'psych-hero-section'}>
      <div className={'psychContainer'}>
        <Row gutter={10} className='BannerTopWrapper'>
          <Col lg={12} md={12} sm={12} xs={24} className='bannerTextWrap'>
            <div className={'hero-container'}>
              <div className={'hero-content-container'}>
                <div className={'header-container'}>
                  <p className={'heading-text'}>
                    GET ACCESS TO MENTAL HEALTH PROVIDERS NEAR YOU WITH
                    IMMEDIATE OPENINGS AND NO WAITLIST, FOR THERAPY AND
                    MEDICATION MANAGEMENT .
                  </p>
                  {/* <p className={'gredient-text'}>Find the help you need!</p> */}
                </div>
              </div>
            </div>
          </Col>
          <Col lg={12} md={12} sm={12} xs={24} className='bannerImgWrap'>
            <div className={'hero-image-container'}>
              <PsychHeroBanner />
            </div>
          </Col>
        </Row>
        <Row gutter={10} className='BannerBottomWrapper'>
          <PsychSearchTherapist setisLoading={setisLoading} />
        </Row>
      </div>
    </div>
  );
};
