import React from 'react';
import './about.scss';
import { HeadAbout } from '../../assets/vectors';
import { useNavigate } from 'react-router-dom';
import Footer from '../../components/footer/Footer';
import Header from '../../components/header/Header';

const About = () => {
  const navigate = useNavigate();
  const showFooter = true;

  return (
    <>
      <Header />
      <div className='about'>
        <div className='head_about'>
          <h1> About Us... </h1>
        </div>
        <div className='content'>
          <p>
            Lorem ipsum dolor sit amet consectetur. Viverra purus eu malesuada
            eget cursus lacus eget. Augue iaculis aliquam ipsum urna sit et
            accumsan vestibulum. Semper elit nulla ut amet eget quis mattis vel.
            Diam tellus bibendum a id lorem etiam sodales. Magna enim risus quam
            diam accumsan lobortis id tellus facilisi. Sem gravida arcu
            scelerisque consectetur viverra in risus odio. Aliquam enim proin
            ultrices pellentesque nisl. Eu tellus rhoncus nisi pellentesque.
            Convallis aliquam magna fringilla ullamcorper pellentesque ac.
            Nullam leo nisi vitae elementum. Massa velit vitae volutpat et
            accumsan nisi. Mi lacinia tempus facilisis felis massa nunc
            ullamcorper quis iaculis. Vel platea commodo volutpat velit enim
            eget. Non quis enim nisi cursus vitae.
          </p>
          <p style={{ padding: '1rem 0' }}>
            Nam nec purus amet viverra in fermentum orci nibh. Duis enim
            suspendisse mattis tortor ultrices morbi risus. Consectetur amet eu
            scelerisque enim turpis fermentum malesuada. Pulvinar urna et metus
            euismod bibendum sit urna odio. Vehicula quis dignissim metus
            gravida donec bibendum sed massa. Ac egestas nulla nibh metus
            faucibus aliquam enim adipiscing est. Ut suspendisse dignissim neque
            in sociis dignissim urna ac praesent. Tincidunt suscipit massa
            placerat gravida orci convallis mus ultrices. Amet tellus sit
            scelerisque facilisis id.
          </p>
          <p>
            Dis eget quam dignissim platea. Ut pellentesque pellentesque at eget
            laoreet id rhoncus dui consectetur. Cursus rhoncus venenatis
            fermentum elementum. Id ultrices pharetra et vitae.
          </p>
        </div>
        <div className='btn_div'>
          <button onClick={() => navigate('/make-appointment')}>
            <img src={HeadAbout} alt='' /> Book appointment
          </button>
        </div>
      </div>
      {showFooter && <Footer color={true} />}
    </>
  );
};

export default About;
