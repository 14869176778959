import dayjs from 'dayjs';
import {
  DoctorABN,
  DoctorFunding,
  DoctorLanguage,
  DoctorProfileAgeGroup,
  DoctorProfileCalender,
  DoctorProfileCertificate,
  DoctorProfileInstitute,
  DoctorProfileProfession,
  DoctorProfileRadius,
  DoctorProfileSoftware,
  DoctorProfileSpeciality,
  DoctorRegistrationNumber,
  DoctorServiceDelivery,
  LocationDoctor,
  ProviderBody,
  websiteIcon
} from '../../assets/vectors';

export const generateProviderProfileData = therapist => {
  return [
    {
      label: 'Profession',

      value:
        therapist?.providerProfile?.practice
          ?.map(category => category.name)
          ?.join(', ') || '--',
      icon: DoctorProfileProfession
    },
    // {
    //   label: 'Special Interest Areas',
    //   value:
    //     therapist?.providerProfile?.practice
    //       ?.flatMap(category =>
    //         category.practiceSpecialties.map(subCategory => subCategory.name)
    //       )
    //       ?.join(', ') || '--',
    //   icon: DoctorProfileSpeciality
    // },
    {
      label: 'Age Group',
      value:
        therapist?.providerProfile?.ageGroup
          ?.map(group => (group === '0' ? 'Infants' : group))
          ?.join(', ') || '--',
      icon: DoctorProfileAgeGroup
    },
    therapist?.providerProfile?.practiceSoftware
      ? {
          label: 'Practice Software',
          value: therapist?.providerProfile?.practiceSoftware || '--',
          icon: DoctorProfileSoftware
        }
      : null,
    therapist?.providerProfile?.website
      ? {
          label: 'Website',
          value: therapist?.providerProfile?.website || '--',
          icon: websiteIcon
        }
      : null,
    {
      label: 'Practice Address/Location',
      value: therapist?.personalInfo?.bussinessAddress?.join('\n') || '--',
      icon: LocationDoctor
    },
    {
      label: 'Practice City',
      value: therapist?.personalInfo?.city || '--',
      icon: LocationDoctor
    },
    {
      label: 'Zip code',
      value: therapist?.personalInfo?.zipCode || '--',
      icon: LocationDoctor
    }
  ].filter(obj => obj !== null);
};
export const generateProviderEducationData = education => {
  return [
    {
      label: 'Institution Name',
      value: education?.institutionName,
      icon: DoctorProfileInstitute
    },
    {
      label: 'Certification Name',
      value: education?.certificationName,
      icon: DoctorProfileCertificate
    },
    {
      label: 'Certification Date',
      value: dayjs(education?.certificationYear).format('DD/MM/YYYY'),
      icon: DoctorProfileCalender
    }
  ].filter(obj => obj !== null);
};
export const generateClinicDetails = clinic => {
  return [
    {
      label: 'Representative name',
      value: clinic?.representativeDetails?.representativeName,
      icon: DoctorProfileProfession
    },
    {
      label: 'Clinic/Practice Address Location',
      value: clinic?.representativeDetails?.clinicAddress,
      icon: LocationDoctor
    },
    {
      label: 'Profession',
      value:
        clinic?.services?.practice
          ?.map(category => category.name)
          ?.join(', ') || '--',
      icon: DoctorProfileProfession
    },
    {
      label: 'Special Interest Areas',
      value:
        clinic?.services?.practice
          ?.flatMap(category =>
            category.practiceSpecialties.map(subCategory => subCategory.name)
          )
          ?.join(', ') || '--',
      icon: DoctorProfileSpeciality
    },
    {
      label: 'Funding options',
      value: clinic?.clinicDetails?.fundingOptions?.join(', ') || '--',
      icon: DoctorFunding
    },
    {
      label: 'Service delivery',
      value: clinic?.clinicDetails?.serviceDelivery?.join(', ') || '--',
      icon: DoctorServiceDelivery
    },
    {
      label: 'Age Group',
      value: clinic?.clinicDetails?.ageGroup?.join(', ') || '--',
      icon: DoctorProfileAgeGroup
    },
    {
      label: 'Website',
      value: clinic?.clinicDetails?.website || '--',
      icon: websiteIcon
    },
    {
      label: 'Practice Software',
      value: clinic?.clinicDetails?.practiceSoftware || '--',
      icon: DoctorProfileSoftware
    }
  ].filter(obj => obj !== null);
};
