import React, { useEffect, useRef, useState } from 'react';
import { Form, Input, List } from 'antd';
import { getCurrentLocationAndFetchAddress } from './GetCurrentLocation';
import './AntdInputAutocomplete.scss';
import { Location, LocationGreen } from '../../assets/vectors';
const AntdInputAutocomplete = ({
  placeholder,
  name,
  form,
  validateStatus = 'validating',
  disabled = false,
  onChangedValues,
  defaultVal = '',
  defaultLat = '',
  defaultLng = '',
  required = false,
  customClass,
  greenIcon
}) => {
  const listRef = useRef(null);

  const [inputValue, setinputValue] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [showList, setShowList] = useState(false);
  const access_token =
    'pk.eyJ1IjoiaW5hYW0xIiwiYSI6ImNramtmNmljYzJhMWMycnFwM28zOHE4ZzIifQ.pBIP97q3Us332iKImTP9aQ';
  const getSuggestion = async () => {
    try {
      const response = await fetch(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${inputValue?.toString()}.json?access_token=${access_token}&country=US`
      );

      if (!response.ok) {
        throw new Error('Network response was not ok');
      } else {
        const data = await response.json();
        setSuggestions(data?.features);
        setShowList(data?.features.length > 0);
      }
    } catch (error) {
      console.error('Error fetching location suggestions:', error);
    }
  };

  const getLocation = () => {
    getCurrentLocationAndFetchAddress()
      .then(res => {
        const { location, latitude, longitude } = res;
        form.setFieldValue('lat', latitude);
        form.setFieldValue('lng', longitude);
        form.setFieldValue(name, location);
        const fieldValues = form.getFieldsValue();
        onChangedValues && onChangedValues(null, fieldValues);
      })
      .catch(err => {
        console.log(err);
      });
  };
  useEffect(() => {
    if (!inputValue) {
      setShowList(false);
    } else {
      getSuggestion();
    }
  }, [inputValue]);
  useEffect(() => {
    form.setFieldValue('providerLocationName', defaultVal);
    form.setFieldValue('lat', defaultLat);
    form.setFieldValue('lng', defaultLng);
  }, []);

  const handleListItemClick = val => {
    const [longitude, latitude] = val.center;
    setShowList(false);
    form.setFieldValue(name, val?.place_name);
    form.setFieldValue('lat', latitude);
    form.setFieldValue('lng', longitude);
    const fieldValues = form.getFieldsValue();
    onChangedValues && onChangedValues(null, fieldValues);
  };

  useEffect(() => {
    const closeList = event => {
      if (listRef.current && !listRef.current.contains(event.target)) {
        setShowList(false);
      }
    };
    document.addEventListener('click', closeList);
    return () => {
      document.removeEventListener('click', closeList);
    };
  }, []);
  return (
    <div className={`addressFieldWrapper ${customClass}`}>
      <Form.Item
        name={name}
        rules={[
          {
            required: required,
            message: 'Location is required'
          }
        ]}
        validateStatus={validateStatus}
      >
        <Input
          value={inputValue ? inputValue : defaultVal}
          onChange={e => {
            setinputValue(e.target.value);
            if (!e.target.value?.length) {
              form.setFieldValue('lat', '');
              form.setFieldValue('lng', '');
              onChangedValues && onChangedValues();
            }
          }}
          suffix={
            <img
              src={greenIcon ? LocationGreen : Location}
              alt=''
              className='setting'
              onClick={getLocation}
              style={{ cursor: 'pointer' }}
            />
          }
          placeholder={placeholder}
          disabled={disabled}
        />
      </Form.Item>
      {showList && suggestions.length > 0 && (
        <List
          // className='locationsList'
          ref={listRef}
          className='customSearchList locationsList'
          bordered
          dataSource={suggestions}
          renderItem={item => (
            <List.Item onClick={() => handleListItemClick(item)}>
              {item?.place_name}
            </List.Item>
          )}
        />
      )}
      <Form.Item name='lat' className='hiddenField'>
        <Input />
      </Form.Item>
      <Form.Item name='lng' className='hiddenField'>
        <Input />
      </Form.Item>
    </div>
  );
};

export default AntdInputAutocomplete;
