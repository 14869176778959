import { configureStore } from '@reduxjs/toolkit';
import persistReducer from 'redux-persist/es/persistReducer';
import storage from 'redux-persist/lib/storage';
import persistStore from 'redux-persist/es/persistStore';
import { rootReducer } from './combineReducer';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['profile', 'searchedTherapists']
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
export const configureAppStore = () => {
  const store = configureStore({
    reducer: persistedReducer,
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        serializableCheck: false
      })
  });
  const persistor = persistStore(store);

  return { store, persistor };
};
