import { Table } from 'antd';
import React from 'react';

export const FollowUpSessions = ({ data }) => {
  function formatDateTime(inputDateString) {
    const inputDate = new Date(inputDateString);
    const day = inputDate.getDate();
    const month = inputDate.getMonth() + 1;
    const year = inputDate.getFullYear();
    const formattedDate = `${day}/${month}/${year}`;
    const hours = inputDate.getHours();
    const minutes = inputDate.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    const formattedTime = `${hours % 12}:${minutes
      .toString()
      .padStart(2, '0')} ${ampm}`;
    const timeZoneAbbreviation = inputDate
      .toLocaleTimeString('en-US', { timeZoneName: 'short' })
      .split(' ')[2];
    const formattedDateTime = `${formattedDate} ${formattedTime} ${timeZoneAbbreviation}`;
    return formattedDateTime;
  }
  const frequencyArray = {
    ONE_OFF: 'One-off',
    WEEKLY: 'Weekly',
    FORTNIGHTLY: 'Fortnightly',
    MONTHLY: 'Monthly'
  };
  const columns = [
    {
      title: 'Date & Time',
      dataIndex: 'scheduleId',
      render: val => {
        return formatDateTime(val?.start);
      }
    },
    {
      title: 'Provider',
      render: val => {
        return data?.therapist?.firstName + ' ' + data?.therapist?.lastName;
      }
    },

    {
      title: 'Patient',
      render: val => {
        return data?.booking?.fullName;
      }
    },
    {
      title: 'Status',
      dataIndex: 'scheduleId',
      render: val => {
        const currentDateTime = new Date();
        const scheduleStart = new Date(val?.start);
        currentDateTime.setUTCSeconds(0);
        currentDateTime.setUTCMilliseconds(0);
        if (currentDateTime.getMinutes() < 30) {
          currentDateTime.setMinutes(0);
        } else {
          currentDateTime.setMinutes(30);
        }
        if (val?.status === 'upcoming' && scheduleStart <= currentDateTime) {
          return 'In progress';
        }
        return val?.status || '';
      }
    },
    {
      title: 'Frequency',
      dataIndex: 'scheduleId',
      render: val => {
        return frequencyArray[val?.scheduleFrequency] || '-';
      }
    }
  ];
  return (
    <div>
      {' '}
      <Table
        columns={columns}
        dataSource={data?.sessions}
        scroll={true}
        pagination={{
          pageSize: 10
        }}
      />
    </div>
  );
};
