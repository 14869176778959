import React, { useEffect, useState } from 'react';
import { Form, Radio } from 'antd';
import Footer from '../footer/Footer';
import Input from '../UI/Input';
import Select from '../UI/Select';
import { useDispatch, useSelector } from 'react-redux';
import { postRequest } from '../../api/apiCalls';
import { useNavigate, useParams } from 'react-router-dom';
import { updateAppointmentField } from '../../Redux/Reducers/apponitmentSlice';
import ButtonGreen from '../UI/Button/ButtonGreen';
import TextArea from '../UI/TextArea';
import { disabledFuturesDates } from '../../helpers/dates';
import AntdDatePicker from '../UI/DatePicker';
import dayjs from 'dayjs';

const PatientInfo = ({ activeStep, setActiveStep }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [form] = Form.useForm();
  const [isLoading, setisLoading] = useState(false);
  const profileData = useSelector(state => state.profile.profileData);
  const token = useSelector(state => state.authReducer.token);
  const user_address = useSelector(state => state.appointment.user_address);
  const selected_slot = useSelector(state => state.appointment.selected_slot);
  const onFinish = async values => {
    setisLoading(true);

    const dataToSend = {
      fullName: values?.fullName,
      gender: values?.gender,
      dob: dayjs(values?.dob),
      email: values?.email,
      isGuardian: values?.relationship === 'guardian' ? true : false,
      type: 'clinic',
      address: user_address || '',
      scheduleId: selected_slot?._id || '',
      reasonForAppointment: values?.reasonForAppointment,
      relationship: values?.relationship,
      relationName: values?.relationName
    };

    const onSuccess = res => {
      setisLoading(false);
      navigate('/consultations');
      dispatch(
        updateAppointmentField({
          field: 'selected_slot',
          value: null
        })
      );
      dispatch(
        updateAppointmentField({
          field: 'user_address',
          value: ''
        })
      );
      dispatch(
        updateAppointmentField({
          field: 'appointment_type',
          value: ''
        })
      );
      dispatch(
        updateAppointmentField({
          field: 'selected_therapist',
          value: ''
        })
      );
    };
    const onError = () => {
      setisLoading(false);
    };
    await postRequest(
      dataToSend,
      !token
        ? `customer/create-guest-booking/${id}`
        : `customer/create-booking/${id}`,
      true,
      true,
      onSuccess,
      onError
    );
  };
  const calculateAge = val => {
    const birthdateDate = new Date(val);
    const currentDate = new Date();
    const ageInMilliseconds = currentDate - birthdateDate;
    const ageInYears = ageInMilliseconds / (1000 * 60 * 60 * 24 * 365.25);
    const age = Math.floor(ageInYears);

    return age;
  };
  useEffect(() => {
    window.scrollTo({
      behavior: 'smooth',
      top: 0
    });
  }, []);
  useEffect(() => {
    if (profileData) {
      const emailValue = profileData?.userId?.email || '';
      const age = calculateAge(profileData?.dob);
      form.setFieldsValue({ email: emailValue });
      form.setFieldsValue({ fullName: profileData?.fullName });
      form.setFieldsValue({ age: age });
      form.setFieldsValue({ gender: profileData?.gender });
      form.setFieldsValue({ relationship: profileData?.relationship });
      form.setFieldsValue({ relationName: profileData?.relationName });
      form.setFieldsValue({ dob: dayjs(profileData?.dob) });
    }
  }, [profileData, form]);

  return (
    <>
      <div className='patientInfo'>
        <Form
          name='basic'
          layout='vertical'
          style={{ width: '100%' }}
          form={form}
          onFinish={onFinish}
          autoComplete='off'
        >
          <div className='input_groups'>
            <Form.Item
              name='fullName'
              rules={[{ required: true, message: 'Full name is Required' }]}
              label='Full name'
            >
              <Input placeholder='Enter your full name.' />
            </Form.Item>
          </div>
          <div style={{ marginTop: '20px' }}>
            <Form.Item
              name={'dob'}
              rules={[{ required: true, message: 'Patient DOB is required' }]}
              label="Patient's date of birth"
            >
              <AntdDatePicker
                disabledDate={disabledFuturesDates}
                required
                placeholder="Enter patient's date of birth"
              />
            </Form.Item>
          </div>
          <div className='input_groups'>
            <Form.Item
              name={'gender'}
              label='Gender'
              rules={[
                {
                  required: true,
                  message: 'Please select gender'
                }
              ]}
            >
              <Select
                placeholder='Choose gender'
                options={[
                  {
                    value: 'male',
                    label: 'Male'
                  },
                  {
                    value: 'female',
                    label: 'Female'
                  },
                  {
                    value: 'other',
                    label: 'Other'
                  }
                ]}
                required
              />
            </Form.Item>
          </div>
          <Form.Item
            name={'relationship'}
            // rules={[
            //   {
            //     required: true,
            //     message: 'Please select relation with patient'
            //   }
            // ]}
          >
            <Radio.Group className='three__fields'>
              <h2 className='relation'> Relation with patient (Optional)</h2>
              <div className='box_one'>
                <Radio value={'parent'} className='customAppointmentRadio'>
                  <div className='box_one_left'>
                    <p> Parent</p>
                  </div>
                </Radio>
              </div>
              <div className='box_one'>
                <Radio value={'guardian'} className='customAppointmentRadio'>
                  <div className='box_one_left'>
                    <p> Guardian</p>
                  </div>
                </Radio>
              </div>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            name='relationName'
            // rules={[
            //   {
            //     required: true,
            //     message: 'Parent/Guardian/Representative name is required'
            //   }
            // ]}
            label='Parent/Guardian/Representative name (Optional)'
          >
            <Input placeholder='Enter Parent/Guardian/Representative name' />
          </Form.Item>
          <div className='input_groups'>
            <Form.Item
              name='email'
              rules={[{ required: true, message: 'Email is required' }]}
              label='Email address'
            >
              <Input
                placeholder='Email Address'
                type='email'
                disabled={token ? true : false}
              />
            </Form.Item>
          </div>
          <div className='input_groups'>
            <Form.Item
              name='reasonForAppointment'
              rules={[{ required: true, message: 'Reason is Required' }]}
              label='Reason'
            >
              <TextArea placeholder='Enter Reason' type='text' />
            </Form.Item>
          </div>

          <div className='timeSlotsBtn'>
            <ButtonGreen
              onClick={() => {
                setActiveStep(prev => prev - 1);
              }}
              text='Back'
              style={{ margin: '10px' }}
              disabled={isLoading}
            />
            <ButtonGreen
              text='Submit request'
              type='submit'
              style={{ margin: '10px' }}
              disabled={isLoading}
            />
          </div>
        </Form>
      </div>

      <Footer />
    </>
  );
};

export default PatientInfo;
