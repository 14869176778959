import { useNavigate } from 'react-router-dom';
import './footer.scss';
import { Footer_logo_Dark, Logo } from '../../../assets/vectors';

const Footer = () => {
  const navigate = useNavigate();
  return (
    <div className='footer-main-container'>
      <div className='footer-content'>
        <div className='logo' onClick={() => navigate('/')}>
          <img src={Footer_logo_Dark} alt='' onClick={() => navigate('/')} />

          {/* <p>PSYCH NOW</p> */}
        </div>

        <p>
          ©2023<span> Psych Now</span>, LLC. All rights reserved.
        </p>
      </div>
    </div>
  );
};

export default Footer;
