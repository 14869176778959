import React, { useEffect, useRef, useState } from 'react';
import {
  DP,
  PsychArrowLeftLight,
  PsychArrowRightLight,
  PsychReviewCover
} from '../../assets/vectors';
import './PsychTestimonials.scss';
import { getRequest } from '../../api/apiCalls';
import { Carousel, Spin } from 'antd';

export const PsychTestimonials = () => {
  const [isLoading, setisLoading] = useState(false);
  const [topReviews, setTopReviews] = useState([]);
  const getTopReviews = async () => {
    setisLoading(true);
    const onSuccess = res => {
      setTopReviews(res?.data);

      setisLoading(false);
    };
    const onError = res => {
      console.log(res, 'erorr res');
      setisLoading(false);
    };
    await getRequest(
      null,
      `customer/get-top-reviews`,
      true,
      true,
      onSuccess,
      onError
    );
  };
  useEffect(() => {
    getTopReviews();
  }, []);
  const swiperRef = useRef(null);

  const handleNext = () => {
    if (swiperRef.current) {
      swiperRef.current.next();
    }
  };

  const handlePrev = () => {
    if (swiperRef.current) {
      swiperRef.current.prev();
    }
  };
  return (
    <Spin spinning={isLoading}>
      {topReviews?.length > 1 ? (
        <div className={'psychTestimonialsWrapper'}>
          <div className={'main-container'}>
            <div className={'button-container'}>
              <button className={'testimonial-buttons'} onClick={handlePrev}>
                <img
                  src={PsychArrowLeftLight}
                  alt=''
                  className={'testimonial-btns'}
                />
              </button>
              <button className={'testimonial-buttons'} onClick={handleNext}>
                <img src={PsychArrowRightLight} alt='' />
              </button>
            </div>
            <div className={'testimonial-slider'}>
              <Carousel dots={false} ref={swiperRef}>
                {topReviews?.map((elem, ind) => {
                  return (
                    <div>
                      <h3>
                        <div className={'testimonial-card'}>
                          <div className={'testimonial-card-image-section'}>
                            <div className={'testimonial-user'}>
                              <img
                                src={
                                  elem?.profile_image ? elem?.profile_image : DP
                                }
                                alt=''
                                className={'testimonial-user-img'}
                              />
                            </div>
                          </div>
                          <div className={'testimonial-content-section'}>
                            <p className={'crd-title'}>
                              <span className={'crd-spn'}>
                                {elem?.fullName}
                              </span>{' '}
                              Says...
                            </p>
                            <p className={'testimonial-text'}>{elem?.review}</p>
                          </div>
                        </div>
                      </h3>
                    </div>
                  );
                })}
              </Carousel>
            </div>
            <img src={PsychReviewCover} alt='' className={'review-cover'} />
          </div>
        </div>
      ) : topReviews?.length ? (
        <div className={'psychTestimonialsWrapper'}>
          <div className={'main-container'}>
            <div className={'testimonial-slider'}>
              {topReviews?.map((elem, ind) => {
                return (
                  <div>
                    <h3>
                      <div className={'testimonial-card'}>
                        <div className={'testimonial-card-image-section'}>
                          <div className={'testimonial-user'}>
                            <img
                              src={
                                elem?.profile_image ? elem?.profile_image : DP
                              }
                              alt=''
                              className={'testimonial-user-img'}
                            />
                          </div>
                        </div>
                        <div className={'testimonial-content-section'}>
                          <p className={'crd-title'}>
                            <span className={'crd-spn'}>{elem?.fullName}</span>{' '}
                            Says...
                          </p>
                          <p className={'testimonial-text'}>{elem?.review}</p>
                        </div>
                      </div>
                    </h3>
                  </div>
                );
              })}
            </div>
            <img src={PsychReviewCover} alt='' className={'review-cover'} />
          </div>
        </div>
      ) : null}
    </Spin>
  );
};
