import React, { useEffect, useState } from 'react';
import './landingPage.scss';

import Footer from '../../components/footer/Footer';
import Header from '../../components/header/Header';
import { getRequest } from '../../api/apiCalls';
import { setProfileData } from '../../Redux/Reducers/clinicProfileSlice';
import { useDispatch, useSelector } from 'react-redux';

import { PsychBanner } from '../../components/landingPage/PsychBanner';
import { PsychFindProvider } from '../../components/landingPage/PsychFindProvider';
import { PsychTopProviders } from '../../components/landingPage/PsychTopProviders';
import { PsychTestimonials } from '../../components/landingPage/PsychTestimonials';
import { Spin } from 'antd';
import { PsychJoinProvider } from '../../components/landingPage/PsychJoinProvider';
import { PsychCategories } from '../../components/landingPage/PsychCategories';

const LandingPage = () => {
  const [categories, setCategories] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const [topReviews, setTopReviews] = useState([]);
  const token = useSelector(state => state.authReducer.token);

  const dispatch = useDispatch();
  const showFooter = true;
  const getAllData = async () => {
    const onSuccess = res => {
      dispatch(setProfileData(res?.detail));
    };
    const onError = res => {
      console.log(res, 'erorr res');
      dispatch(setProfileData(null));
    };
    await getRequest(null, 'auth/me', true, true, onSuccess, onError);
  };
  const getCategoriesHandler = async () => {
    setisLoading(true);
    const onSuccess = res => {
      setCategories(res?.data);
      setisLoading(false);
    };
    const onError = () => {
      setisLoading(false);
    };
    await getRequest(
      null,
      'category/get-category',
      true,
      false,
      onSuccess,
      onError
    );
  };
  const getTopReviews = async () => {
    setisLoading(true);
    const onSuccess = res => {
      setTopReviews(res?.data);

      setisLoading(false);
    };
    const onError = res => {
      console.log(res, 'erorr res');
      setisLoading(false);
    };
    await getRequest(
      null,
      `customer/get-top-reviews`,
      true,
      true,
      onSuccess,
      onError
    );
  };

  useEffect(() => {
    if (token) {
      getAllData();
    }
    getCategoriesHandler();
    getTopReviews();
  }, []);

  return (
    <>
      <div className='landing__page'>
        <Header />
        <Spin spinning={isLoading}>
          <PsychBanner setisLoading={setisLoading} />
          <PsychFindProvider />
          <PsychCategories categories={categories} />
          <PsychTopProviders />
          <PsychJoinProvider />
          <PsychTestimonials />
        </Spin>

        {showFooter && <Footer color={true} />}
      </div>
    </>
  );
};

export default LandingPage;
