import React, { useEffect, useState } from 'react';
import { getRequest } from '../../api/apiCalls';
import { useMediaQuery } from 'react-responsive';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';
import { Col, Row } from 'antd';
import './SingleProfile.scss';
import {
  Achievement,
  CalendarXmark,
  DoctorProfileProfession,
  DoctorWaitTimeIcon,
  EmailIcon,
  Left_Btn,
  PhoneIcon
} from '../../assets/vectors';

import { DoctorsCalender } from '../../components/Profiles/DoctorsCalender';
import { SearchWaitlistForm } from '../searchTherapist/SearchWaitlistForm';
import { DP } from '../../assets/images';

import SpinLoader from '../../components/UI/Loader';
import ButtonGreen from '../../components/UI/Button/ButtonGreen';
import {
  generateProviderEducationData,
  generateProviderProfileData
} from './constants';
export const SingleDoctorProfile = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const isTab = useMediaQuery({ query: '(max-width: 1024px)' });
  const isMobile = useMediaQuery({ query: '(max-width: 540px)' });
  const token = useSelector(state => state.authReducer.token);
  const [therapist, setTherapist] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [waitlistActiveThearapist, setWaitlistActiveThearapist] = useState('');
  const getDateForNextNDays = n => {
    const currentDate = new Date();
    const nextNDays = new Date(currentDate.setDate(currentDate.getDate() + n));
    return nextNDays.toISOString().split('T')[0];
  };
  const schedulesForNext7Weeks = Array.from({ length: 7 * 7 }, (_, index) => {
    const nextDayDate = getDateForNextNDays(index);
    const schedules = therapist?.Schedule?.filter(schedule => {
      const scheduleDate = new Date(schedule.start).toISOString().split('T')[0];
      return scheduleDate === nextDayDate;
    });
    return { date: nextDayDate, schedules };
  });

  const getAllData = async () => {
    setIsLoading(true);
    const onSuccess = res => {
      setTherapist(res?.data);
      setIsLoading(false);
    };
    const onError = res => {
      console.log(res, 'erorr res');
      setIsLoading(false);
    };
    await getRequest(
      null,
      `therapist/single-therapist/${id}`,
      true,
      true,
      onSuccess,
      onError
    );
  };
  const providerProfileData = generateProviderProfileData(therapist);
  const providerEducationData = generateProviderEducationData(
    therapist?.education[0]
  );
  useEffect(() => {
    window.scrollTo({
      behavior: 'smooth',
      top: 0
    });
    getAllData();
  }, []);

  return (
    <>
      <Header />
      {isLoading ? (
        <SpinLoader />
      ) : (
        <>
          <div className='doctorDetailsWrapper'>
            <Row>
              <Col span={isTab ? 24 : 16} className='detailsWrapper'>
                <div className='doctorBackButton' onClick={() => navigate(-1)}>
                  <img src={Left_Btn} alt='Previous' />
                  <p> Back </p>
                </div>
                <div className='headDetails'>
                  <div className='profileImg'>
                    {therapist?.personalInfo?.profileImg ? (
                      <img src={therapist?.personalInfo?.profileImg} alt='' />
                    ) : (
                      <DP />
                    )}
                  </div>
                  <div className='doctorName'>
                    <h3>
                      {therapist?.personalInfo?.firstName +
                        ' ' +
                        therapist?.personalInfo?.lastName}{' '}
                    </h3>

                    <h4>{therapist?.providerProfile?.practice?.name}</h4>
                    <div className='headTags'>
                      <a
                        className='singleTag'
                        href={`tel:${therapist?.providerProfile?.phoneNumber}`}
                      >
                        <img src={PhoneIcon} alt='' />

                        {/* <PhoneOutlined /> */}
                        {therapist?.providerProfile?.phoneNumber}
                      </a>

                      <a
                        className='singleTag'
                        href={`mailto:${therapist?.personalInfo?.email}`}
                      >
                        <img src={EmailIcon} alt='' />

                        {/* <MailOutlined /> */}
                        {therapist?.personalInfo?.email}
                      </a>
                    </div>
                  </div>
                </div>
                <div className='siteMemberAchievements'>
                  {/* <div className='singleAchievement'>
                    <img src={Achievement} alt='$$' />{' '}
                    <div className='content'>
                      <h4>Highly recommended</h4>
                      <p>100% of patients gave this doctor 5 stars</p>
                    </div>
                  </div>
                  <div className='singleAchievement'>
                    {' '}
                    <img src={DoctorWaitTimeIcon} alt='$$' />
                    <div className='content'>
                      <h4>Excellent wait time</h4>
                      <p>92% of patients waited less than 30 minutes</p>
                    </div>
                  </div> */}
                  <div className='singleAchievement fullWidth'>
                    {' '}
                    <img src={DoctorProfileProfession} alt='$$' />
                    {/* <ClockCircleFilled /> */}
                    <div className='content'>
                      <h4>
                        {' '}
                        About{' '}
                        {therapist?.personalInfo?.firstName +
                          ' ' +
                          therapist?.personalInfo?.lastName}
                      </h4>
                      <p>{therapist?.personalInfo?.bio}</p>
                    </div>
                  </div>
                </div>
              </Col>
              <Col span={isTab ? 24 : 8}>
                {' '}
                <div className='slotsWrapper'>
                  {schedulesForNext7Weeks?.some(
                    item => item?.schedules?.length > 0
                  ) ? (
                    <DoctorsCalender
                      data={schedulesForNext7Weeks || []}
                      id={id}
                      doctorData={therapist}
                    />
                  ) : (
                    <div className='noSlotWrapper'>
                      <span>
                        <img src={CalendarXmark} alt='$$$' />
                        <p> No bookable appointment available</p>
                      </span>
                      <ButtonGreen
                        text='Join waitlist'
                        className='Make_an_appointment'
                        onClick={() => {
                          if (token) {
                            setWaitlistActiveThearapist(id);
                            setOpenModal(true);
                          } else {
                            navigate('/login');
                          }
                        }}
                      />
                    </div>
                  )}
                </div>
              </Col>
            </Row>
          </div>

          <div className='doctorDetailsWrapper'>
            <h2 className='doctorDetailHeading'>Provider Profile</h2>
            <Row gutter={16} style={{ marginTop: '20px' }}>
              {providerProfileData?.filter(Boolean)?.map((profileInfo, ind) => {
                return (
                  <Col
                    style={{ marginBottom: '20px ' }}
                    span={isMobile ? 24 : isTab ? 12 : 8}
                  >
                    <div className='memberDetailCard'>
                      <div className='singleDetailWrapper'>
                        <h4 className='customSingleViewHeading'>
                          <img src={profileInfo?.icon} alt='$$' />
                          <span> {profileInfo?.label}</span>
                        </h4>
                        <p
                          style={{ whiteSpace: 'pre-wrap' }}
                          className='customSingleViewParagraph'
                        >
                          {profileInfo?.value}
                        </p>
                      </div>
                    </div>
                  </Col>
                );
              })}
            </Row>
          </div>
        </>
      )}

      <Footer color={true} />
      <SearchWaitlistForm
        setOpenModal={setOpenModal}
        openModal={openModal}
        waitlistActiveThearapist={waitlistActiveThearapist}
      />
    </>
  );
};
