import { combineReducers } from '@reduxjs/toolkit';
import { authReducer } from './Reducers/authSlice';
import { profileReducer } from './Reducers/clinicProfileSlice';
import { appointmentReducer } from './Reducers/apponitmentSlice';
import { searchedTherapistsReducer } from './Reducers/searchedTherapistsSlice';

const combinedReducer = combineReducers({
  authReducer: authReducer,
  profile: profileReducer,
  appointment: appointmentReducer,
  searchedTherapists: searchedTherapistsReducer
});
export const rootReducer = (state, action) => {
  if (action.type === 'user/logout') {
    state = undefined;
  }
  return combinedReducer(state, action);
};
