import React, { useEffect, useState } from 'react';
import './account.scss';
import { Form } from 'antd';
import Footer from '../footer/Footer';
import Header from '../header/Header';
import { UploadIcon } from '../../assets/images';
import AntdDatePicker from '../UI/DatePicker';
import AntdInput from '../UI/Input';
import Select from '../UI/Select';
import { disabledFuturesDates } from '../../helpers/dates';
import { getRequest, putRequest } from '../../api/apiCalls';
import axios from 'axios';
import { BASE_URL } from '../../constants';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import SpinLoader from '../UI/Loader';
import { EditOutlined } from '@ant-design/icons';
import { setProfileData } from '../../Redux/Reducers/clinicProfileSlice';
import { ImageCropper } from '../UI/ImageCropper';
import ButtonGreen from '../UI/Button/ButtonGreen';
import AntdPassword from '../UI/Password';

const MyAccount = () => {
  const dispatch = useDispatch();
  const [isUploading, setisUploading] = useState(false);
  const [profileData, setprofileData] = useState(null);
  const [relation, setrelation] = useState('');
  const profileImg = useSelector(
    state => state?.profile?.profileData?.profile_image
  );
  const [profileImage, setprofileImage] = useState(profileImg);
  const [isLoading, setisLoading] = useState(false);
  const [imageURL, setImageURL] = useState(null);
  const [form] = Form.useForm();
  const intialValue = {
    fullName: '',
    email: '',
    password: '',
    dob: ''
  };
  const showFooter = true;
  const getAllData = async () => {
    setisLoading(true);
    const onSuccess = res => {
      setprofileData(res?.detail);
      setrelation(res?.detail?.relationship);
      setisLoading(false);
      dispatch(setProfileData(res?.detail));
    };
    const onError = res => {
      console.log(res, 'erorr res');
      setisLoading(false);
    };
    await getRequest(null, 'auth/me', true, true, onSuccess, onError);
  };
  const handleFileChange = fileList => {
    setisUploading(true);
    setisLoading(true);
    const formData = new FormData();
    setprofileImage(URL.createObjectURL(fileList[0]));

    formData.append('images', fileList[0]);
    axios
      .post(`${BASE_URL}/auth/upload_image_s3`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(response => {
        setImageURL(response?.data?.path[0]?.image_file_name);
        setisUploading(false);
        setisLoading(false);
      })
      .catch(error => {
        console.error('Upload error', error);
        setisUploading(false);
        setisLoading(false);
      });
  };
  const onFinish = async val => {
    setisLoading(true);
    const dataToSend = {
      fullName: val.fullName,
      dob: val.dob,
      gender: val.gender,
      relationship: val.relationship || '',
      relationName: val.relationName
    };
    if (imageURL !== null) {
      dataToSend.profile_image = imageURL;
    }
    const onSuccess = res => {
      getAllData();
      setisLoading(false);
    };
    const onError = err => {
      setisLoading(false);
    };
    await putRequest(
      dataToSend,
      `customer/update_customer`,
      true,
      true,
      onSuccess,
      onError
    );
  };
  const handlePasswordChange = async values => {
    setisLoading(true);
    const dataToSend = {
      new_password: values.new_password,
      confirm_password: values.confirm_password,
      old_password: values.old_password
    };
    const onSuccess = res => {
      setisLoading(false);
    };
    const onError = err => {
      setisLoading(false);
    };
    await putRequest(
      dataToSend,
      `auth/change_password`,
      true,
      true,
      onSuccess,
      onError
    );
  };
  const userRelationData = {
    parent: (
      <>
        <Form.Item
          name='relationName'
          rules={[
            {
              required: true,
              message: 'Parent name is required'
            }
          ]}
          label='Parent name'
        >
          <AntdInput placeholder='Enter parent name' />
        </Form.Item>
      </>
    ),
    guardian: (
      <>
        <Form.Item
          name='relationName'
          rules={[
            {
              required: true,
              message: 'Guardian name is required'
            }
          ]}
          label='Guardian name'
        >
          <AntdInput placeholder='Enter guardian name' />
        </Form.Item>
      </>
    ),
    representative: (
      <>
        <Form.Item
          name='relationName'
          rules={[
            {
              required: true,
              message: 'Representative name is required'
            }
          ]}
          label='Representative name'
        >
          <AntdInput placeholder='Enter representative name' />
        </Form.Item>
      </>
    )
  };
  useEffect(() => {
    getAllData();
  }, []);
  useEffect(() => {
    setprofileImage(profileImg);
  }, [profileImg]);
  useEffect(() => {
    const date = dayjs(profileData?.dob);
    form.setFieldsValue({
      fullName: profileData?.fullName,
      email: profileData?.userId?.email,
      dob: date,
      gender: profileData?.gender,
      relationName: profileData?.relationName
    });
    if (profileData?.relationship) {
      form.setFieldsValue({
        relationship: profileData?.relationship
          ? profileData?.relationship
          : null
      });
    }
  }, [profileData]);
  return (
    <>
      <Header />
      {isLoading ? (
        <SpinLoader />
      ) : (
        <div className='account'>
          <div className='headbg'></div>
          <div className='head_account'>
            <div className='account-upload-img'>
              <ImageCropper handleFileChange={handleFileChange} />
              <label htmlFor='account-image'>
                <EditOutlined className='updateImageLogo' />
              </label>
              {profileImage ? (
                <img src={profileImage} />
              ) : (
                <>
                  <UploadIcon />
                  <p>Change Image</p>
                </>
              )}
            </div>
            <h2 className='accountname'> {profileData?.fullName} </h2>
          </div>
          <div className='accountForm'>
            <Form
              name='basic'
              layout='vertical'
              style={{ width: '100%' }}
              form={form}
              initialValues={intialValue}
              onFinish={onFinish}
              autoComplete='off'
            >
              <Form.Item
                name='fullName'
                rules={[
                  { required: true, message: 'Patient/child name is Required' }
                ]}
                label='Patient/child name'
              >
                <AntdInput placeholder='Enter your patient/child name' />
              </Form.Item>

              <Form.Item
                name={'dob'}
                rules={[{ required: true, message: 'Patient DOB is Required' }]}
                label="Patient's Date of birth"
              >
                <AntdDatePicker
                  disabledDate={disabledFuturesDates}
                  required
                  placeholder="Enter patient's date of birth"
                />
              </Form.Item>

              <Form.Item
                name='email'
                rules={[
                  {
                    required: true,
                    message: 'Reachable email address is Required'
                  }
                ]}
                label='Reachable email address'
              >
                <AntdInput
                  placeholder='Email Address'
                  type='email'
                  required
                  disabled
                />
              </Form.Item>
              <Form.Item
                name='gender'
                rules={[
                  {
                    required: true,
                    message: 'patient/child gender is required'
                  }
                ]}
                label='Patient/child gender'
              >
                <Select
                  placeholder='Select patient/child gender'
                  options={[
                    { label: 'Male', value: 'male' },
                    { label: 'Female', value: 'female' },
                    { label: 'Other', value: 'other' }
                  ]}
                />
              </Form.Item>
              <Form.Item
                name='relationName'
                // rules={[
                //   {
                //     required: true,
                //     message: 'Parent/Guardian/Representative name is required'
                //   }
                // ]}
                label='Parent/Guardian/Representative name (Optional)'
              >
                <AntdInput placeholder='Enter Parent/Guardian/Representative name' />
              </Form.Item>
              <Form.Item
                name='relationship'
                // rules={[
                //   {
                //     required: true,
                //     message: 'Relationship with patient is required'
                //   }
                // ]}
                label='Relationship with patient (Optional)'
              >
                <Select
                  placeholder='Select relationship with patient'
                  options={[
                    { label: 'Parent', value: 'parent' },
                    { label: 'Guardian', value: 'guardian' },
                    { label: 'Representative', value: 'representative' }
                  ]}
                  onChange={val => {
                    setrelation(val);
                  }}
                />
              </Form.Item>
              {userRelationData[relation]}

              <div className='btn-center-single login-button'>
                <ButtonGreen
                  disabled={isUploading}
                  text={'Update Profile'}
                  type='submit'
                />
              </div>
            </Form>
          </div>
          <div className='accountForm'>
            <Form
              name='basic'
              layout='vertical'
              style={{ width: '100%' }}
              onFinish={handlePasswordChange}
              autoComplete='off'
            >
              <Form.Item
                label='Old Password'
                name={'old_password'}
                rules={[
                  {
                    required: true,
                    validator: async (_rule, value) => {
                      if (!value) {
                        return Promise.reject('Password is Required');
                      }
                    }
                  }
                ]}
              >
                <AntdPassword placeholder='Choose a password' />
              </Form.Item>
              <Form.Item
                label='New Password'
                name={'new_password'}
                rules={[
                  {
                    required: true,
                    validator: async (_rule, value) => {
                      if (!value) {
                        return Promise.reject('Password is Required');
                      } else if (value.length < 6) {
                        return Promise.reject('Password must be 6 characters');
                      }
                    }
                  }
                ]}
              >
                <AntdPassword placeholder='Choose a password' />
              </Form.Item>
              <Form.Item
                label='Confirm Password'
                name={'confirm_password'}
                dependencies={['new_password']}
                rules={[
                  {
                    required: true,
                    message: 'Please Confirm your password'
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value) {
                        return Promise.resolve();
                      }
                      if (getFieldValue('new_password') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        'The new password that you entered does not match!'
                      );
                    }
                  })
                ]}
              >
                <AntdPassword placeholder='Confirm  password' />
              </Form.Item>

              <div className='btn-center-single login-button'>
                <ButtonGreen
                  disabled={isUploading}
                  text={'Update Password'}
                  type='submit'
                />
              </div>
            </Form>
          </div>
        </div>
      )}
      {showFooter && <Footer color={false} />}
    </>
  );
};

export default MyAccount;
