import { useLocation } from 'react-router-dom';
import Footer from './Footer/Footer';
import HeaderLogin from './AuthHeaders/HeaderLogin';
import HeaderSignUp from './AuthHeaders/HeaderSignup';

export const AuthLayout = ({ children }) => {
  const { pathname } = useLocation();
  return (
    <>
      {pathname.includes('/login') ? (
        <>
          <HeaderLogin />
        </>
      ) : pathname === '/signup' ? (
        <>
          <HeaderSignUp />
        </>
      ) : (
        ''
      )}
      {children}
      <Footer />
    </>
  );
};
